import React from "react";
import { withRouter } from "react-router-dom";
import Tour from "reactour";

export const UserTour = withRouter(
  ({
    isOpen,
    closeTour,
    expandLayout,
    curr,
    location: { pathname },
    history,
  }) => {
    const steps = [
      {
        selector: "[data-tour='reactour__1']",
        content: () => (
          <div>
            <span color="#e5e5e5">Start here</span>
          </div>
        ),
        style: {
          backgroundColor: "black", //"#f16ea5CC",
          color: "white",
        },
      },
      {
        selector: "[data-tour='reactour__2']",
        content: () => (
          <div>
            <span color="#e5e5e5">
              Click here to create your business listing
            </span>
          </div>
        ),
        style: {
          backgroundColor: "black",
          color: "white",
        },
        action: () => {
          //   if (pathname === "/process-voucher") {
          expandLayout();
          //   }
        },
      },
      {
        selector: "[data-tour='reactour__3']",
        content: () => (
          <div>
            <span color="#e5e5e5">Click here to add image and edit text</span>
          </div>
        ),
        style: {
          backgroundColor: "black",
          color: "white",
        },
        action: () => {
          if (pathname !== "/profile-manager") {
            history.push("/profile-manager");
          }
        },
      },
      {
        selector: "[data-tour='reactour__4']",
        content: () => (
          <div>
            <span color="#e5e5e5">Securely enter bank details in Stripe</span>
          </div>
        ),
        style: {
          backgroundColor: "black",
          color: "white",
        },
      },
      {
        selector: "[data-tour='reactour__5']",
        content: () => (
          <div>
            <span color="#e5e5e5">Manage staff roles in this section</span>
          </div>
        ),
        style: {
          backgroundColor: "black",
          color: "white",
        },
      },
      {
        selector: "[data-tour='reactour__6']",
        content: () => (
          <div>
            <span color="#e5e5e5">Find your unique QR code in here</span>
          </div>
        ),
        style: {
          backgroundColor: "black",
          color: "white",
        },
      },
    ];

    return (
      <Tour
        steps={steps}
        isOpen={isOpen}
        onRequestClose={closeTour}
        update={pathname}
        goToStep={steps}
        getCurrentStep={(curr) =>
          console.log(`The current step is ${curr + 1}`)
        }
        disableDotsNavigation
        // disableInteraction
        lastStepNextButton={<span style={{ color: "#e5e5e5" }}>Let's Go</span>}
        closeWithMask={false}
        showCloseButton={false}
      />
    );
  }
);
