import React from 'react';

const DownloadIcon = () => {
    return (
        <svg viewBox="0 0 24 24" width="32" height="32">
            <path
                d="M11.293,17.707a1,1,0,0,0,.325.216.986.986,0,0,0,.764,0,1,1,0,0,0,.325-.216l4-4a1,1,0,0,0-1.414-1.414L13,14.586V3a1,1,0,0,0-2,0V14.586L8.707,12.293a1,1,0,1,0-1.414,1.414Z"
                fill="#5e53a3" ></path>
            <path d="M22,12a1,1,0,0,0-1,1v7H3V13a1,1,0,0,0-2,0v8a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V13A1,1,0,0,0,22,12Z"
                  fill="#5e53a3" ></path>
        </svg>
    );
};

export default DownloadIcon;