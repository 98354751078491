import * as api from '..';
import { STORES } from '../config/endpoints';

interface StoreService {
    getStores: (body: {}) => Promise<any>;
    addStore: (body: {}) => Promise<any>;
    updateStore: (body: {}) => Promise<any>;
    getCategories: (body: {}) => Promise<any>;
}

const StoreService: StoreService = {
    getStores: async () => {
        const resp = await api.get(STORES.getStores);
        return resp;
    },
    addStore: async (body) => {
        const resp = await api.post(STORES.addStore, body);
        return resp;
    },
    updateStore: async (body) => {
        const resp = await api.post(STORES.updateStore, body);
        return resp;
    },
    getCategories: async (body) => {
        const resp = await api.get(STORES.getCategories);
        return resp;
    }
};

export default StoreService;
