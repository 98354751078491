import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import VoucherService from "../../../api/services/voucherService";
import { ToastContext } from "../../../context/toastProvider";
import { VoucherContext } from "../../../context/ValidateVoucherContext";
import Loader from "../../loader/loader";

export default withRouter(function EnterVoucher(props) {
  const [code, setCode] = useState("");
  const [showValid, setShowValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { initToast } = useContext(ToastContext);
  const { initVoucher } = useContext(VoucherContext);

  const VerifyVoucher = async () => {
    setIsLoading(true);
    try {
      const resp = await VoucherService.voucherStatus({ voucherCode: code });
      if (resp.success) {
        initVoucher({
          amount: resp.voucherStatus[0].AMOUNT,
          available: resp.voucherStatus[0].AVAILABLE,
          voucherCode: resp.voucherStatus[0].VOUCHERCODE,
          purchaseDate: resp.voucherStatus[0].PURCHASEDATE,
        });
        initToast("success", resp.message);
        props.history.push(
          `/process-voucher/redeem/${resp.voucherStatus[0].VOUCHERCODE}`
        );
        setIsLoading(false);
      } else {
        initToast("error", resp.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Loader isLoading={isLoading} />
      <div className="row g-1">
        <div className="col-6">
          <label
            for="voucher"
            className="border-bottom pb-3 mb-0 font-weight-bold text-uppercase"
          >
            Enter Voucher Code
          </label>
          <input
            className="form-control"
            type="text"
            id="voucher"
            placeholder=""
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
        <div
          className="col-12"
          style={{ display: showValid ? "block" : "none" }}
        >
          <p className="small right">Voucher is not valid</p>
        </div>
        <div className="col-12" style={{ marginTop: "1.6em" }}>
          <button onClick={() => VerifyVoucher()} className="btn btn-primary">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
});
