import React, { useContext, useState, useEffect } from "react";
import { Formik } from "formik";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import StoreService from "../../api/services/storeService";
import { ToastContext } from "../../context/toastProvider";
import Loader from "../loader/loader";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

function StoreAdd({ modal, toggle }) {
  const [isLoading, setIsLoading] = useState(false);
  const { initToast } = useContext(ToastContext);
  const history = useHistory();
  const [placesObj, setPlacesObj] = useState();
  const [latLng, setlatLng] = useState({ lat: "", lng: "" });
  const [categories, setCategories] = useState();

  const handlePlace = async (value) => {
    setPlacesObj(value);
    geocodeByPlaceId(value.value.place_id)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => setlatLng({ lat, lng }));
  };

  useEffect(() => {
    onPageInit();
  }, []);

  const onPageInit = async () => {
    try {
      const resp = await StoreService.getCategories();
      if (resp) {
        setCategories(resp.data);
        console.log(resp);
      }
    } catch (error) {}
  };

  return (
    <Modal isOpen={modal}>
      <ModalHeader className="font-weight-bold text-uppercase">
        Add Store
      </ModalHeader>
      <ModalBody>
        <Loader isLoading={isLoading} />
        <Formik
          initialValues={{
            storeName: "",
            streetAddress1: "",
            streetAddress2: "",
            suburb: "",
            city: "",
            state: "",
            ZIP: "",
            coordinates: latLng.lng !== "" ? latLng.lat + "," + latLng.lng : "",
            storeDescription: "",
            emailAddress: "",
            contactNumber: "",
            categoryID: "1",
          }}
          enableReinitialize
          onSubmit={async (values) => {
            console.log(values);
            setIsLoading(true);
            try {
              const resp = await StoreService.addStore(values);
              if (resp.success) {
                initToast("success", resp.message);
                setIsLoading(false);
                history.goBack();
              } else {
                initToast("error", resp.message);
                setIsLoading(false);
              }
            } catch (error) {
              setIsLoading(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting, //AIzaSyBNycLXtWLf5skIdK6Umu87C33r3-TAzKU
          }) => (
            <form className="row g-1 p-2" onSubmit={handleSubmit}>
              <div className="col-12 p-1"></div>
              <div className="col-12 p-1">
                <label for="suburb">Coordinates</label>
                <GooglePlacesAutocomplete
                  className="form-control"
                  apiKey="AIzaSyDgWDAYziwrfcUclkU-1R52RnzlWr0RT0s"
                  selectProps={{
                    placesObj,
                    onChange: handlePlace,
                  }}
                />
                <input
                  className="form-control mt-2"
                  id="coordinates"
                  name="coordinates"
                  value={values.coordinates}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="col-12 p-1">
                <label for="storeName">Store Name</label>
                <input
                  className="form-control"
                  id="storeName"
                  name="storeName"
                  value={values.storeName}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="col-12 p-1">
                <label htmlFor="storeEmail">Store Email Address</label>
                <input
                  className="form-control"
                  id="emailAddress"
                  name="emailAddress"
                  value={values.emailAddress}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="col-12 p-1">
                <label htmlFor="storeNumber">Store Contact Number</label>
                <input
                  className="form-control"
                  id="contactNumber"
                  name="contactNumber"
                  value={values.contactNumber}
                  onChange={handleChange}
                  type="number"
                  placeholder=""
                />
              </div>
              <div className="col-12 p-1">
                <label htmlFor="storeNumber">Store Category</label>
                <select
                  className="form-control"
                  id="categoryID"
                  name="categoryID"
                  placeholder="Store Name"
                  onChange={handleChange}
                >
                  <option value={0} disabled selected>
                    Select a Store...
                  </option>
                  {categories?.map((item) => (
                    <option value={item.CATEGORYID}>{item.CATEGORYNAME}</option>
                  ))}
                </select>
              </div>
              <div className="col-12 p-1">
                <label for="street">Street</label>
                <input
                  className="form-control"
                  id="streetAddress1"
                  name="streetAddress1"
                  value={values.streetAddress1}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="col-12 p-1">
                <label for="street">Building</label>
                <input
                  className="form-control"
                  id="streetAddress2"
                  name="streetAddress2"
                  value={values.streetAddress2}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="col-12 p-1">
                <label for="state">State</label>
                <input
                  className="form-control"
                  id="state"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="col-12 p-1">
                <label for="city">City</label>
                <input
                  className="form-control"
                  id="city"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="col-12 p-1">
                <label for="suburb">Suburb</label>
                <input
                  className="form-control"
                  id="suburb"
                  name="suburb"
                  value={values.suburb}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="col-12 p-1">
                <label for="city">ZIP Code</label>
                <input
                  className="form-control"
                  id="ZIP"
                  name="ZIP"
                  value={values.ZIP}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                />
              </div>
              <div className="col-12 p-1">
                <label for="suburb">Store Description</label>
                <textarea
                  className="form-control"
                  id="storeDescription"
                  name="storeDescription"
                  value={values.storeDescription}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                />
              </div>
              <Button
                style={{ marginRight: 10, marginTop: 10 }}
                color="btn btn-primary"
                type="submit"
              >
                Submit
              </Button>
              <Link to="/store-manager">
                <Button
                  style={{ marginLeft: 10, marginTop: 10 }}
                  color="btn btn-secondary"
                  onClick={toggle}
                >
                  Cancel
                </Button>
              </Link>
            </form>
          )}
        </Formik>
      </ModalBody>
      {/* <ModalFooter>
                <Link to="/profile-manager">
                    <Button color="btn btn-primary" onClick={toggle}>
                        Done
                    </Button>
                </Link>
            </ModalFooter> */}
    </Modal>
  );
}

export default StoreAdd;
