import React, { useState, useContext } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from "reactstrap";
import { Formik } from "formik";
import { useHistory, Link } from "react-router-dom";
import partnerService from "../../api/services/partnerService";
import { ToastContext } from "../../context/toastProvider";
import Loader from "../loader/loader";

function ProfileEdit({ modal, toggle, partnerInfo }) {
  const [banner, setBanner] = useState();
  const [logo, setLogo] = useState();
  const { initToast } = useContext(ToastContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileReadLogo = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    setLogo(base64.replace(/^data:image\/[a-z]+;base64,/, ""));
  };

  const handleFileReadBanner = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    setBanner(base64.replace(/^data:image\/[a-z]+;base64,/, ""));
  };

  return (
    <Modal isOpen={modal}>
      <Loader isLoading={isLoading} />
      <ModalHeader className="font-weight-bold text-uppercase">
        Edit Profile
      </ModalHeader>
      <Formik
        initialValues={{
          partnerName: partnerInfo ? partnerInfo.name : "",
          partnerDescription: partnerInfo ? partnerInfo.description : "",
          givebackPercent: partnerInfo ? partnerInfo.giveBack : "",
          bannerFile: "",
          logoFile: "",
          active: "1",
        }}
        onSubmit={async (values) => {
          setIsLoading(true);
          try {
            const resp = await partnerService.updatePartner({
              ...values,
              bannerFile: banner,
              logoFile: logo,
            });

            if (resp.success) {
              initToast("success", resp.message);
              setIsLoading(false);
              history.goBack();
              toggle();
            } else {
              initToast("error", resp.message);
              setIsLoading(false);
            }
          } catch (error) {
            setIsLoading(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <ModalBody className="row g-1 p-4 font-weight-bolder">
              <div className="col-12 p-1">
                <label htmlFor="name">Partner Name</label>
                <input
                  className="form-control"
                  id="partnerName"
                  type="text"
                  name="partnerName"
                  value={values.partnerName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 p-1">
                <label htmlFor="email">Give-Back Percent</label>
                <input
                  className="form-control"
                  id="givebackPercent"
                  name="givebackPercent"
                  type="number"
                  onChange={handleChange}
                  value={values.givebackPercent}
                />
              </div>
              <div className="col-12 p-1">
                <label htmlFor="logo">Logo</label>
                <input
                  className="form-control"
                  id="logo"
                  type="file"
                  name="logo"
                  onChange={(e) => handleFileReadLogo(e)}
                />
              </div>
              <div className="col-12 p-1">
                <label htmlFor="banner">
                  Banner (Your banner will be resized to 600px x 300px)
                </label>
                <input
                  className="form-control"
                  id="bannerFile"
                  type="file"
                  name="bannerFile"
                  onChange={(e) => handleFileReadBanner(e)}
                />
              </div>
              <div className="col-12 p-1">
                <label htmlFor="surname">Description</label>
                <input
                  className="form-control"
                  id="partnerDescription"
                  style={{ minHeight: "150px" }}
                  type="text"
                  name="partnerDescription"
                  value={values.partnerDescription}
                  onChange={handleChange}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="btn btn-primary" type="submit">
                Update
              </Button>
              <Link to="/profile-manager">
                <Button color="btn btn-secondary" onClick={toggle}>
                  Cancel
                </Button>
              </Link>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

export default ProfileEdit;
