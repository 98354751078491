import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { Switch, Route } from "react-router-dom";
import ManageCampaign from "../../components/campaigns/ManageCampaign";
import AddCampaign from "../../components/campaigns/AddCampaign";
import EditCampaign from "../../components/campaigns/EditCampaign";

function CampaignManager() {
    return (
        <div>
            <Row>
                <Col>
                    <Switch>
                        <Route path="/campaign-manager/edit-campaign/:id" key="EditCampaign" component={EditCampaign} />
                        <Route path="/campaign-manager/create" key="AddCampaign" component={AddCampaign} />
                        <Route path="/campaign-manager/" key="ManageCampaign" component={ManageCampaign} />
                    </Switch>
                </Col>
            </Row>
        </div>
    );
}

export default CampaignManager;
