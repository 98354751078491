import axios from "axios";
import { emit } from "../helper/eventEmitter";

let token: string | null = null;

export const setToken = (t: string) => {
  token = t;
};

const axiosDefault = axios.create({
  withCredentials: false,
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "*",
    // "Access-Control-Allow-Headers": "*",
    "Content-Type": "application/json",
    //@ts-ignore
    "Access-Control-Allow-Credentials": true,
  },
});

axiosDefault.interceptors.request.use(
  (request) => {
    if (token) {
      if (request.headers) request.headers["Authorization"] = token;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosDefault.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

const get = async (url: string): Promise<any> => {
  try {
    const resp = await axiosDefault.get(url);
    if (resp) {
      return resp.data;
    }
  } catch (error: any) {
    if (error?.response?.status === 401) {
      emit("logout", "");
    }
  }
  return false;
};

const post = async (url: string, body: any, token?: any): Promise<any> => {
  try {
    const resp = await axiosDefault.post(url, body);
    if (resp) {
      return resp.data;
    }
  } catch (error: any) {
    if (error?.response?.status === 401) {
      emit("logout", "");
    }
  }
  return false;
};

const deleteCall = async (url: string): Promise<any> => {
  try {
    const resp = await axiosDefault.delete(url);
    if (resp) {
      return resp.data;
    }
  } catch (error: any) {
    if (error?.response?.status === 401) {
      emit("logout", "");
    }
  }
  return false;
};

export { get, post, deleteCall };
