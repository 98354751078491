import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Switch, Route } from "react-router-dom";
import EnterVoucher from "./VoucherRedemption/EnterVoucher";
import RedeemVoucher from "./VoucherRedemption/RedeemVoucher";
export default function ValidateVoucher() {
  return (
    <Card>
      <CardTitle className="border-bottom p-3 mb-0 font-weight-bold text-uppercase">
        <i class="fas fa-check-double"></i>
        Validate Voucher
      </CardTitle>
      <CardBody>
        <p className="text-secondary">
          Enter the voucher number shown on your customer’s mobile device and
          click Submit to process. Select the store or category from the
          dropdown menu and click, Redeem.
        </p>
        <Switch>
          <Route
            path="/process-voucher/redeem/:code"
            render={(props) => <RedeemVoucher {...props} />}
          />
          <Route
            path="/process-voucher"
            render={(props) => <EnterVoucher {...props} />}
          />
        </Switch>
      </CardBody>
    </Card>
  );
}
