import React from "react";
import Chart from "react-apexcharts";
import { Card, CardBody, CardTitle } from "reactstrap";

const TotalRevenue = ({ series }) => {
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      stacked: true,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 4,
      colors: ["transparent"],
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "flat",
      },
    },
    colors: ["#7460ee", "#968ae7"],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    responsive: [
      {
        breakpoint: 2500,
        options: {
          plotOptions: {
            bar: {
              columnWidth: "30%",
            },
          },
        },
      },
    ],
  };

  return (
    <Card className="card">
      <CardBody className="card-body">
        <div className="d-md-flex align-items-center">
          <CardTitle className=" font-weight-bold text-uppercase">
            Voucher Purchases
          </CardTitle>
          <div className="ml-auto">
            <ul className="list-inline mb-2">
              <li className="list-inline-item">
                <h6 className="text-muted mb-0">
                  <i
                    className="fa fa-circle mr-1"
                    style={{ color: "#7460ee" }}
                  ></i>
                  Voucher Amount
                </h6>
              </li>
            </ul>
          </div>
        </div>
        <div className="clear"></div>
        {series.data.length > 0 && (
          <div className="total-sales" style={{ height: "339px" }}>
            <Chart
              options={options}
              series={[series]}
              type="bar"
              height="339"
            />
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default TotalRevenue;
