import React from "react";
import {
    Card,
} from "reactstrap";


function PurchaseCredits() {

    return (
        <Card style={{padding: 20}}>
            <p style={{textAlign: 'center', margin: 0}}>Coming Soon!</p>
        </Card>
    );
}

export default PurchaseCredits;
