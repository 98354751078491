const AUTH = {
  initClient: "initClient",
  login: "partners/login",
  loginOTP: "partners/otp",
};

const STORES = {
  getStores: "partners/get-stores/",
  addStore: "partners/add-stores",
  updateStore: "partners/update-stores",
  getCategories: "web/get-categories",
};

const STAFF = {
  updateStaff: "partners/staff/update",
  deleteStaff: "partners/staff/delete/",
  getAllStaff: "staff/get/all",
  addStaff: "partners/staff/add",
  getStaffMember: "staff/get/",
};

const PARTNER = {
  getPartner: "partners/get-partner/",
  updatePartner: "/partners/update-partner",
  resetPin: "partners/stores/staff/reset-pin",
  verifyToken: "partners/verify-token",
  buyCredits: "partners/buy-credits",
};

const VOUCHERS = {
  voucherStatus: "partners/vouchers/status",
  voucherRedeem: "partners/vouchers/redeem",
  voucherHistory: "partners/vouchers/history",
};

const CAMPAIGNS = {
  getCampaigns: "partners/campaigns/get/",
  createCampaign: "partners/campaigns/add",
  updateCampaign: "partners/campaigns/update",
  getCampaignGroups: "partners/campaigns/groups/get/",
  addMember: "partners/campaigns/members/add",
  updateMember: "partners/campaigns/members/update",
  deleteMember: "partners/campaigns/members/delete/",
  getCredits: "partners/credits",
  sendCampaign: "partners/campaigns/send",
  csvUpload: "partners/campaigns/bulk-add-subscribers",
  updateStatus: "partners/campaigns/update-status",
};

const QRCODE = {
  getQrCode: "partners/qr-codes/create-link",
};

const BANK = {
  getBank: "partners/get-bank/",
  updateBank: "partners/update-bank/",
  stripeLoginLink: "stripe/login-link",
  stripeaccountLink: "stripe/account-link",
};

export { AUTH, STORES, STAFF, VOUCHERS, PARTNER, CAMPAIGNS, QRCODE, BANK };
