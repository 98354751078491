import React, { useState, useContext, useEffect } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import StoreService from "../../api/services/storeService";
import GooglePlacesAutoComplete, {
  getLatLng,
  geocodeByAddress,
} from "react-google-places-autocomplete";
import PhoneInput from "react-phone-number-input";
import { ToastContext } from "../../context/toastProvider";

function EditStore({ match, modal, toggle, storeList }) {
  let store = storeList.find((o) => o.STORENAME === match.params.name);
  const [placesObj, setPlacesObj] = useState();
  const [latLng, setlatLng] = useState({ lat: "", lng: "" });
  const [mobileNumber, setMobileNumber] = useState();
  const { initToast } = useContext(ToastContext);
  const history = useHistory();
  const [val, setVal] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [inputVal, setInputVal] = useState("");
  const [values, setValues] = useState();
  const [categories, setCategories] = useState();
  const [full, setFull] = useState(null);

  useEffect(() => {
    initCats();
  }, []);

  const initCats = async () => {
    try {
      const cats = await StoreService.getCategories();
      if (cats) {
        setCategories(cats.data);
      }
    } catch (error) {}
  };
  const getSuburbCity = (results) => {
    let suburb = "";
    let city = "";
    let postalCode = "";
    let state = "";
    let streetAddress = "";
    results.address_components.forEach((comp) => {
      if (comp.types.includes("sublocality")) suburb = comp.short_name;
      if (comp.types.includes("locality")) city = comp.short_name;
      if (comp.types.includes("postal_code")) postalCode = comp.short_name;
      setIsValid(true);
      if (comp.types.includes("administrative_area_level_1"))
        state = comp.long_name;
    });
    streetAddress = results.formatted_address.split(",")[0];
    return [suburb, city, postalCode, state, streetAddress];
  };

  useEffect(() => {
    handleChange();
  }, [val]);

  const handleChange = async () => {
    if (val !== "") {
      const latLng = await geocodeByAddress(val);
      let lat = latLng[0].geometry.location.lat();
      let lng = latLng[0].geometry.location.lng();
      const [suburb, city, postalCode, state, streetAddress] =
        await geocodeByAddress(val).then((results) =>
          getSuburbCity(results[0])
        );
      setPlacesObj({
        suburb: suburb,
        city: city,
        state: state,
        ZIP: postalCode,
        streetAddress1: streetAddress,
        coordinates: String(lat) + "," + String(lng),
      });
    }
  };

  return (
    <Modal isOpen={modal}>
      <ModalHeader className="font-weight-bold text-uppercase">
        Edit Store:
      </ModalHeader>
      <Formik
        initialValues={{
          storeName: store ? store.STORENAME : "",
          streetAddress1: store ? store.STREETADDRESS1 : "",
          streetAddress2: store ? store.STREETADDRESS2 : "",
          suburb: store ? store.SUBURB : "",
          city: store ? store.CITY : "",
          state: store ? store.STATE : "",
          ZIP: store ? store.ZIP : "",
          coordinates: store ? store.LATITUDE + "," + store.LONGITUDE : "",
          emailAddress: store ? store.EMAILADDRESS : "",
          contactNumber: store ? store.CONTACTNUMBER : "",
          categoryID: store ? store.CATEGORYID : "",
          storeID: store ? store.STOREID : "",
          active: "1",
          delete: "0",
        }}
        enableReinitialize
        onSubmit={async (values) => {
          try {
            const resp = await StoreService.updateStore({
              ...values,
              ...placesObj,
            });
            console.log(resp, "resp");
            if (resp.success) {
              history.goBack();
              initToast("success", resp.message);
            } else {
              initToast("error", resp.message);
            }
          } catch (error) {}
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="">
            <ModalBody>
              <div className="col-12 p-1">
                <label htmlFor="storeName">Store Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="storeName"
                  value={values.storeName}
                  onChange={handleChange}
                  name="storeName"
                />
              </div>
              <div className="col-12 p-1">
                <label htmlFor="storeEmail">Store Email Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="emailAddress"
                  value={values.emailAddress}
                  onChange={handleChange}
                  name="emailAddress"
                />
              </div>
              <div className="col-12 p-1">
                <label htmlFor="categories">Category</label>
                <select
                  className="form-control"
                  id="categoryID"
                  placeholder="Categories"
                  selected={values.CATEGORYID}
                  onChange={handleChange}
                >
                  {categories
                    ? categories.map((x) => (
                        <option
                          selected={x.CATEGORYID == values.categoryID}
                          value={x.CATEGORYID}
                        >
                          {x.CATEGORYNAME}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div className="col-12 p-1">
                <label htmlFor="storeNumber">Store Contact Number</label>

                <PhoneInput
                  className="form-control"
                  id="contactNumber"
                  value={values.contactNumber}
                  name="contactNumber"
                  defaultCountry="US"
                  onChange={(e) => setMobileNumber(e)}
                  style={{ display: "flex" }} //AIzaSyCjHBjPgcKI_w-F1-7TT6i5csVzbN4fj7s
                />
              </div>
              <div className="col-12 p-1">
                <label htmlFor="street">Street</label>
                <GooglePlacesAutoComplete
                  apiKey="AIzaSyDgWDAYziwrfcUclkU-1R52RnzlWr0RT0s"
                  className="form-control"
                  value={values.streetAddress1 + "," + values.city}
                  selectProps={{
                    styles: {
                      control: (provided) => ({
                        ...provided,
                        border: !isValid ? "1px solid red" : "0.5px solid grey",
                      }),
                    },
                    placeholder: values
                      ? values.streetAddress1 + "," + values.city
                      : "Start typing your address",
                    onInputChange: (e) => {
                      setInputVal(e);
                    },
                    onChange: (e) => {
                      setFull(e);
                      setVal(e.value.description);
                    },
                    onMenuClose: () => {
                      setInputVal(val);
                    },
                    onFocus: (e) => {
                      setVal(inputVal);
                      setInputVal("");
                      setFull(null);
                    },
                    inputValue: inputVal,
                    blurInputOnSelect: true,
                    // styles: {
                    //     input: (provided) => ({
                    //         ...provided,
                    //         minWidth: 300,
                    //     }),
                    // },
                  }}
                />
                {/* <input
                                    type="text"
                                    className="form-control"
                                    id="streetAddress1"
                                    value={values.streetAddress1}
                                    onChange={handleChange}
                                    name="streetAddress1"
                                /> */}
              </div>
              {/* <div className="col-12 p-1">
                                <label htmlFor="state">State</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="state"
                                    value={values.state}
                                    onChange={handleChange}
                                    name="state"
                                />
                            </div>
                            <div className="col-12 p-1">
                                <label htmlFor="city">City</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    value={values.city}
                                    onChange={handleChange}
                                    name="city"
                                />
                            </div>
                            <div className="col-12 p-1">
                                <label htmlFor="suburb">Suburb</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="suburb"
                                    value={values.suburb}
                                    onChange={handleChange}
                                    name="suburb"
                                />
                            </div>
                            <div className="col-12 p-1">
                                <label htmlFor="suburb">ZIP Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="ZIP"
                                    value={values.ZIP}
                                    onChange={handleChange}
                                    name="ZIP"
                                />
                            </div>
                            <div className="col-12 p-1">
                                <label htmlFor="street">Coordinates</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="coordinates"
                                    value={values.coordinates}
                                    onChange={handleChange}
                                    name="coordinates"
                                />
                            </div> */}
            </ModalBody>
            <ModalFooter>
              <Button color="btn btn-primary" onClick={handleSubmit}>
                Update
              </Button>
              <Link to="/store-manager">
                <Button color="btn btn-secondary" onClick={toggle}>
                  Cancel
                </Button>
              </Link>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

export default EditStore;
