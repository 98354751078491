import UserManager from "../views/user-manager/index.jsx";
import ProcessVoucher from "../views/process-voucher/index.jsx";
import ProfileManager from "../views/profile-manager/index.jsx";
import StoreManager from "../views/store-manager/index.jsx";
import CampaignManager from "../views/create-campaign/index.jsx";
import GenerateQrCode from "../views/generarte-qrcode/index.jsx";
import BankingDetails from "../components/bankingDetails/BankingDetails.jsx";
import PurchaseCredits from "../components/purchaseSms/purchaseCredits.jsx";

var ThemeRoutes = [
  {
    path: "/process-voucher",
    name: "Process Voucher",
    icon: "fas fa-shopping-cart",
    component: ProcessVoucher,
  },
  {
    path: "/campaign-manager",
    name: "Campaign Manager",
    icon: "fas fa-file-alt",
    component: CampaignManager,
  },
  {
    path: "/profile-manager",
    name: "Company Profile Manager",
    icon: "fas fa-building",
    subMenu: true,
    component: ProfileManager,
  },
  {
    path: "/user-manager",
    name: "User Manager",
    icon: "fas fa-users",
    subMenu: true,
    component: UserManager,
  },

  {
    path: "/store-manager",
    name: "Store Manager",
    icon: "fas fa-shopping-basket",
    subMenu: true,
    component: StoreManager,
  },
  {
    path: "/generate-qrcode",
    name: "Generate QR Code",
    icon: "fas fa-qrcode",
    component: GenerateQrCode,
    subMenu: true,
  },
  {
    path: "/payment-details",
    name: "Generate QR Code",
    icon: "fas fa-qrcode",
    component: BankingDetails,
    subMenu: true,
  },
  {
    path: "/purchase-credits",
    name: "Generate QR Code",
    icon: "fas fa-qrcode",
    component: PurchaseCredits,
    subMenu: true,
  },

  { path: "/", pathTo: "/login", name: "Login", redirect: true },
];
export default ThemeRoutes;
