var events = [];

export const addEventListener = (key, callback) => {
  events.push({ key, callback });
};

export const emit = (key, data) => {
  events.find((item) => item.key === key).callback(data);
};

export const removeEventListener = (key) => {
  events = events.filter((item) => item.key !== key);
};
