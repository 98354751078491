import React, { useState, useEffect, useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Header from "./layout-components/header/header.jsx";
import Sidebar from "./layout-components/sidebar/sidebar.jsx";
import Footer from "./layout-components/footer/footer.jsx";
import ThemeRoutes from "../routes/routing.jsx";
import { AuthContext } from "../context/authProvider";
import Login from "../views/login/index.jsx";
import { setToken } from "../api/index";
import { ToastContainer } from "react-toastify";
import ToastContextProvider from "../context/toastProvider.js";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import bubbles from "../assets/images/background/dotsBg.png";
import logo from "../assets/images/logo-pink.png";
import partnerService from "../api/services/partnerService";
import Loader from "../components/loader/loader.jsx";
import LoginBg from "../assets/images/background/LoginBg.jpg";
import { UserTour } from "./layout-components/tour/index.jsx";
import { addEventListener } from "../helper/eventEmitter.js";

const Fulllayout = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const { loggedIn } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isInitialPopup, setShowInitialPopup] = useState(false);
  const [subMenu, setSubMenu] = React.useState(true);

  useEffect(() => {
    //@ts-ignore
    if (typeof window !== "undefined") {
      function handleResize() {
        const minWidth = 1024;
        const windowWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        setIsDesktop(windowWidth >= minWidth);
      }

      // Initial check
      //@ts-ignore
      handleResize();

      // Attach event listener for window resize
      //@ts-ignore
      window.addEventListener("resize", handleResize);

      addEventListener("loginCount", (data) => {
        if (data < 2) {
          setShowInitialPopup(true);
          localStorage.setItem("initialPopupManageProfile", "1");
          localStorage.setItem("initialPopupCampaionManage", "1");
          document.body.addEventListener("click", handleOnClick);
        }
      });

      // Cleanup the event listener on component unmount
      return () => {
        //@ts-ignore
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    initCheck();
  }, [loggedIn]);

  const handleOnClick = () => {
    setShowInitialPopup(false);
    document.body.removeEventListener("click", () => {});
  };

  const initCheck = async () => {
    if (localStorage.getItem("token") !== "") {
      let jwt = await localStorage.getItem("token");
      setToken(jwt);
      try {
        const resp = await partnerService.verifyToken();
        console.log(resp);
        if (resp && resp.success) {
          setIsLoading(false);
        } else {
          localStorage.removeItem("token");
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  props.history.listen((location, action) => {
    if (typeof window !== "undefined") {
      if (
        window.innerWidth < 767 &&
        document
          .getElementById("main-wrapper")
          .className.indexOf("show-sidebar") !== -1
      ) {
        document
          .getElementById("main-wrapper")
          .classList.toggle("show-sidebar");
      }
    }
  });

  useEffect(() => {
    const updateDimensions = () => {
      if (typeof window !== "undefined") {
        let element = document.getElementById("main-wrapper");
        setWidth(window.innerWidth);
        if (width < 1170) {
          element.setAttribute("data-sidebartype", "mini-sidebar");
          element.classList.add("mini-sidebar");
        } else {
          element.setAttribute("data-sidebartype", "full");
          element.classList.remove("mini-sidebar");
        }
      }
      if (document.readyState === "complete") {
        updateDimensions();
      }
      window.addEventListener("resize", updateDimensions.bind(this));
      window.addEventListener("load", updateDimensions.bind(this));
      return () => {
        window.removeEventListener("load", updateDimensions.bind(this));
        window.removeEventListener("resize", updateDimensions.bind(this));
      };
    };
  }, [width]);

  return (
    <>
      {isDesktop ? (
        <div
          id="main-wrapper"
          data-theme="light"
          data-layout="vertical"
          data-sidebartype="full"
          data-sidebar-position="fixed"
          data-header-position="fixed"
          data-boxed-layout="full"
        >
          <ToastContextProvider>
            <Loader isLoading={isLoading} />
            <Header />
            {localStorage.getItem("token") ? (
              <Sidebar
                {...props}
                routes={ThemeRoutes}
                subMenu={subMenu}
                setSubMenu={setSubMenu}
                isInitialPopup={isInitialPopup}
                setShowInitialPopup={setShowInitialPopup}
              />
            ) : null}
            <div
              className={
                localStorage.getItem("token")
                  ? "page-wrapper d-block"
                  : "page-wrapper d-block ml-0"
              }
            >
              <ToastContainer
                position="top-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <div className="page-content container-fluid">
                <Switch>
                  {ThemeRoutes.map((prop, key) => {
                    if (!localStorage.getItem("token"))
                      return (
                        <Route
                          path={prop.path}
                          to={"/Login"}
                          component={Login}
                        />
                      );
                    else if (prop.redirect) {
                      return (
                        <Redirect from={prop.path} to={prop.pathTo} key={key} />
                      );
                    } else {
                      return (
                        <Route
                          path={prop.path}
                          component={prop.component}
                          key={key}
                        />
                      );
                    }
                  })}
                </Switch>
              </div>
              <Footer />
            </div>
          </ToastContextProvider>
          <img src={bubbles} className="bubbles" />
        </div>
      ) : (
        <div
          className="page-content container-fluid"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
            alignContent: "center",
            height: "100vh",
            flexDirection: "column",
          }}
        >
          <img
            src={LoginBg}
            style={{ position: "absolute", zIndex: 1, height: "100vh" }}
          />
          <div
            style={{
              zIndex: 2,
              backgroundColor: "#FFFFFFF0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 20,
              justifyItems: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={logo}
              style={{
                height: 120,
                width: 120,
                objectFit: "contain",
                zIndex: 2,
              }}
            />
            <h4
              style={{
                textAlign: "center",
                zIndex: 2,
                padding: 20,
                paddingTop: 0,
              }}
            >
              For the best experience, access your secure dashboard on a tablet,
              laptop or desktop computer.
            </h4>
          </div>
        </div>
      )}
      <UserTour
        isOpen={isTourOpen}
        closeTour={() => setIsTourOpen(false)}
        expandLayout={() => setSubMenu(true)}
      />
    </>
  );
};
export default Fulllayout;
