/* eslint-disable */
import moment from "moment";
import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";

const Feeds = ({ data }) => {
  return (
    <Card className="card">
      <CardBody className="card-body">
        <CardTitle className="font-weight-bold text-uppercase">
          Latest Redemptions
        </CardTitle>
        <ul className="feeds pl-0 mb-0 mt-3 pt-1">
          {data
            .reverse()
            .filter((_, index) => index < 6)
            .map((item) => {
              return (
                <li className="feed-item d-flex p-2 align-items-center">
                  <a className="btn btn-circle d-flex align-items-center justify-content-center bg-light-success">
                    <i className="ti-server"></i>
                  </a>
                  <div className="ml-3 text-truncate">{item.VOUCHERCODE}</div>
                  <div className="justify-content-end text-truncate ml-auto">
                    <span className="text-muted font-12">
                      {moment(moment.utc(item.DATEADDED).local()).fromNow()}
                    </span>
                  </div>
                </li>
              );
            })}
        </ul>
      </CardBody>
    </Card>
  );
};

export default Feeds;
