import React, { createContext, useState } from "react";
import VoucherService from "../api/services/voucherService";

export const VoucherContext = createContext();

function VoucherContextProvider({ children }) {
  const [voucher, setVoucher] = useState({
    amount: 0,
    available: 0,
    purchaseDate: "",
    voucherCode: "",
  });
  const [data, setData] = React.useState([]);
  const [series, setSeries] = React.useState({
    name: "Voucher Amount",
    data: [],
  });

  const initVoucher = (body) => {
    setVoucher(body);
  };

  const getData = async () => {
    try {
      const res = await VoucherService.voucherHistory({
        startDate: "",
        endDate: "",
        storeID: "",
      });
      const { transactionHistory, success } = res;
      if (success) {
        const groupedData = transactionHistory.reduce(
          (accumulator, currentValue) => {
            const month = currentValue.DATEADDED.split("-")[1];
            const localData = { ...accumulator };
            if (month in localData) {
              localData[month] = [...localData[month]];
            } else {
              localData[month] = [];
            }
            localData[month].push(currentValue.AMOUNT);
            return localData;
          },
          {}
        );

        let localArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (let key of Object.keys(groupedData)) {
          const sum = groupedData[key].reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );
          localArray[Number(key) - 1] = sum;
        }

        setSeries({
          name: "Voucher Amount",
          data: localArray,
        });
        setData(transactionHistory);
      }
    } catch (error) {
      console.log("Error:: ", error);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <VoucherContext.Provider
      value={{ voucher, initVoucher, data, series, getData }}
    >
      {children}
    </VoucherContext.Provider>
  );
}

export default VoucherContextProvider;
