import React from 'react';
import {
    Row,
    Col
} from 'reactstrap';
import { Route, Switch } from 'react-router-dom';

import UserList from '../../components/userManager/UserList'
import EditUser from '../../components/userManager/EditUser'
import AddUser from '../../components/userManager/AddUser'

export default function UserManager() {

    return(
        <div>
            <Row>
                <Col sm={12}>
                    <Switch>
                        <Route path="/user-manager/edit-user/:id" key="EditUser" component={EditUser} />
                        <Route path="/user-manager/add-user" key="AddUser" component={AddUser} />
                        <Route path="/user-manager" key="UserList" component={UserList} />
                    </Switch>
                </Col>
            </Row>
        </div>
    )
}