import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import Loader from "../loader/loader";
import { ToastContext } from "../../context/toastProvider";
import campaignService from "../../api/services/campaignService";
import { Formik } from "formik";
import { useCSVReader } from "react-papaparse";
import Switch from "react-switch";
import PhoneInput from "react-phone-number-input";
import { emit } from "../../helper/eventEmitter";

function ManageCampaign() {
  const [campaignList, setCampaignList] = useState([{}]);
  const [isLoading, setIsLoading] = useState();
  const { initToast } = useContext(ToastContext);
  const [delConfirm, setDelConfirm] = useState(false);
  const [delUserConfirm, setDelUserConfirm] = useState(-1);
  const [list, setList] = useState(false);
  const [campaignGroup, setCampaignGroup] = useState();
  const [editMember, setEditMember] = useState();
  const [campID, setCampID] = useState();
  const [sendConfirm, setSendConfirm] = useState();
  const [addMember, setAddMember] = useState(false);
  const [member, setMember] = useState();
  const { CSVReader } = useCSVReader();
  const [csv, setCsv] = useState({ csv: {}, isActive: false });
  const [mobileNumber, setMobileNumber] = useState();

  const moment = require("moment");

  useEffect(() => {
    onPageInit();
  }, [setCampaignList, delConfirm]);

  const onPageInit = async () => {
    setIsLoading(true);
    try {
      const resp = await campaignService.getCampaigns("all");
      if (resp.code == 0) {
        console.log(resp);
        setCampaignList(resp.campaignList);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }

    try {
      const groupResp = await campaignService.getCampaignGroups("16");
      if (groupResp.code == 0) {
        console.log(groupResp);
        setCampaignGroup(groupResp.getcampaignSubscribers);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const uploadCsv = async () => {
    try {
      const resp = await campaignService.uploadCsv(csv.csv);
      if (resp.status) {
        initToast("success", resp.message);
        setList(false);
        onPageInit();
      } else {
        initToast("error", resp.message);
      }
    } catch (error) {}
  };

  const deselectUpdate = async (data) => {
    if (data.ACTIVE === 1) {
      try {
        const resp = await campaignService.updateMember({
          ...data,
          ACTIVE: 0,
        });
        initToast("success", resp.message);
        setIsLoading(false);
        onPageInit();
      } catch (error) {}
    } else {
      try {
        const resp = await campaignService.updateMember({
          ...data,
          ACTIVE: 1,
        });
        initToast("success", resp.message);
        setIsLoading(false);
        onPageInit();
      } catch (error) {}
    }
  };

  const initAddMember = () => {
    setAddMember(true);
    setEditMember(true);
  };

  const updateCampaign = async (id) => {
    let campaign = campaignList.find((o) => o.CAMPAIGNID == id);
    let body = {
      campaignName: campaign.CAMPAIGNNAME,
      campaignID: id,
      campaignDescription: campaign.CAMPAIGNDESCRIPTION,
      minimumValue: campaign.MINIMUMVALUE,
      startDate: campaign.STARTDATE,
      campaignIntro: "Hello",
      dashboardMemo: "Hello",
      endDate: campaign.ENDDATE,
      active: campaign.ACTIVE,
      deleted: "1",
    };
    try {
      const resp = await campaignService.updateCampaign(body);
      setDelConfirm(false);
      if (resp && resp.success) {
        initToast("success", resp.message);
      }
    } catch (error) {}
  };

  const sendCampaign = async (body) => {
    try {
      const resp = await campaignService.sendCampaign(body);
      if (resp && resp.success) {
        initToast("success", resp.message);
        setSendConfirm(false);
      } else {
        initToast("error", resp.message);
        setSendConfirm(false);
      }
    } catch (error) {}
    emit("recall-sms-credits", "");
  };

  const onStatusChange = async (oldStatus, index) => {
    const newStatus = oldStatus === 1 ? 0 : 1;
    try {
      const resp = await campaignService.updateStatus({
        campaignID: campaignList[index].CAMPAIGNID,
        active: newStatus,
      });
      if (resp.success) {
        setCampaignList((prev) => {
          let localData = [...prev];
          localData[index].ACTIVE = newStatus;
          return localData;
        });
      } else {
        initToast("error", resp.message);
      }
    } catch (error) {}
  };

  return (
    <Card>
      <Loader isLoading={isLoading} />
      <CardTitle className="border-bottom p-3 mb-0 font-weight-bold text-uppercase">
        <i class="fas fa-users-cog"></i>
        Campaign Manager
        <Link to="/campaign-manager/create">
          <Button
            className="btn"
            color="primary"
            size="md"
            style={{ float: "right" }}
          >
            <i className="fas fa-user-plus"></i> Add Campaign
          </Button>
        </Link>
        <Button
          onClick={() => setList(true)}
          className="btn btn-outline-primary"
          size="md"
          color="transparent"
          style={{ float: "right", marginRight: 10 }}
        >
          Manage Database
        </Button>
      </CardTitle>
      <CardBody>
        <p className="text-secondary">
          Set up and manage promotional campaigns to send to your database.
          <br />
          {
            "You can create as many campaigns as you like, but only two (2) can be active at any one time."
          }
        </p>
        <table className="table stylish-table mb-0 mt-2 no-wrap v-middle">
          <thead>
            <tr>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                Name
              </th>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                Start Date
              </th>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                End Date
              </th>
              <th
                className=" font-weight-bold text-muted border-0 border-bottom "
                style={{
                  position: "relative",
                }}
              >
                Status
              </th>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                Edit
              </th>
              <th className="font-weight-bold text-muted border-0 border-bottom">
                Delete
              </th>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                Send
              </th>
            </tr>
          </thead>
          <tbody>
            {campaignList.map((campaigns, index) => (
              <tr className={"font-weight-bolder"}>
                {console.log(campaigns)}
                <td>{campaigns.CAMPAIGNNAME}</td>
                <td>
                  {moment(new Date(campaigns.STARTDATE)).format("MM/DD/yyyy")}
                </td>
                <td>
                  {moment(new Date(campaigns.ENDDATE)).format("MM/DD/yyyy")}
                </td>
                <td
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  <span
                    className={
                      campaigns.ACTIVE == 1
                        ? "badge badge-light-success text-success"
                        : "badge badge-light-danger text-danger"
                    }
                  >
                    {campaigns.ACTIVE == 1 ? "Active" : "Disabled"}
                  </span>
                  <Switch
                    onChange={() => onStatusChange(campaigns.ACTIVE, index)}
                    checked={campaigns.ACTIVE === 1}
                    onColor={"#7460ee"}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </td>
                <td>
                  <Link
                    to={`/campaign-manager/edit-campaign/${campaigns.CAMPAIGNID}`}
                  >
                    <i className="fas fa-edit"></i>
                  </Link>
                </td>
                <td className="center">
                  <button
                    onClick={() => {
                      if (campaigns.ACTIVE == 1) {
                        setCampID(campaigns.CAMPAIGNID);
                        setDelConfirm(true);
                      }
                    }}
                    style={{
                      border: "none",
                      background: "transparent",
                    }}
                  >
                    <i
                      style={{
                        color: campaigns.ACTIVE == 1 ? "#009efb" : "#808080",
                      }}
                      className="fas fa-trash"
                    />
                  </button>
                  <Modal isOpen={delConfirm}>
                    <ModalHeader className="font-weight-bold text-uppercase">
                      Confirm Delete
                    </ModalHeader>
                    <ModalBody>
                      <p>
                        You are about to delete this store. Please note this
                        cannot be undone
                      </p>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        className="btn"
                        color="primary"
                        size="md"
                        style={{ float: "right" }}
                        onClick={() => updateCampaign(campID)}
                      >
                        Confirm
                      </Button>
                      <Button
                        className="btn"
                        color="secondary"
                        size="md"
                        style={{ float: "right" }}
                        onClick={() => setDelConfirm(false)}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </td>
                <td>
                  <button
                    onClick={() => {
                      if (campaigns.ACTIVE == 1) {
                        setCampID(campaigns.CAMPAIGNID);
                        setSendConfirm(true);
                      }
                    }}
                    style={{
                      border: "none",
                      background: "transparent",
                    }}
                  >
                    <i
                      style={{
                        color: campaigns.ACTIVE == 1 ? "#009efb" : "#808080",
                      }}
                      className="fa fa-paper-plane"
                    />
                  </button>
                  <Modal isOpen={sendConfirm}>
                    <ModalHeader className="font-weight-bold text-uppercase">
                      Confirm Send
                    </ModalHeader>
                    <ModalBody>
                      <p>
                        You are about to send this campaign. Are you sure you
                        would like to continue?
                      </p>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        className="btn"
                        color="primary"
                        size="md"
                        style={{ float: "right" }}
                        onClick={() =>
                          sendCampaign({
                            campaignID: campID,
                            groupID: "16",
                          })
                        }
                      >
                        Confirm
                      </Button>
                      <Button
                        className="btn"
                        color="secondary"
                        size="md"
                        style={{ float: "right" }}
                        onClick={() => setSendConfirm(false)}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </CardBody>
      <Modal
        isOpen={list}
        onClose={() => setList(false)}
        style={{ maxWidth: 1000 }}
      >
        <Loader isLoading={isLoading} />
        {!editMember ? (
          <>
            <CardTitle className="border-bottom p-3 mb-0 font-weight-bold text-uppercase">
              Database Manager
              <Button
                className="btn"
                color="primary"
                size="md"
                style={{ float: "right" }}
                onClick={() => initAddMember()}
              >
                <i className="fas fa-user-plus"></i> Add Members
              </Button>
            </CardTitle>
            <CardBody>
              <p
                className="text-secondary"
                style={{ whiteSpace: "break-spaces" }}
              >
                {
                  "Customers who have subscribed, or opted-in to receive messages will appear here.\nYou can also add members to your database manually, or by uploading a CSV file."
                }
              </p>
              <table className="table stylish-table mb-0 mt-2 no-wrap v-middle">
                <thead>
                  <tr>
                    <th className=" font-weight-bold text-muted border-0 border-bottom">
                      Select
                    </th>
                    <th className=" font-weight-bold text-muted border-0 border-bottom">
                      Name
                    </th>
                    <th className=" font-weight-bold text-muted border-0 border-bottom">
                      Email
                    </th>
                    <th className=" font-weight-bold text-muted border-0 border-bottom">
                      Mobile Number
                    </th>
                    <th className=" font-weight-bold text-muted border-0 border-bottom">
                      Edit
                    </th>
                    <th className=" font-weight-bold text-muted border-0 border-bottom">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {campaignGroup?.map((members, index) => (
                    <tr
                      className={"font-weight-bolder"}
                      key={Math.random().toString()}
                    >
                      <td>
                        <input
                          class="ml-2"
                          type="checkbox"
                          checked={members.ACTIVE}
                          onClick={() => {
                            setIsLoading(true);
                            deselectUpdate(members);
                          }}
                        ></input>
                      </td>
                      <td>
                        {members.FIRSTNAME !== null
                          ? members.FIRSTNAME + " " + members.LASTNAME
                          : "-"}
                      </td>
                      <td>
                        {members.EMAILADDRESS !== null
                          ? members.EMAILADDRESS
                          : "-"}
                      </td>
                      <td>{members.MOBILENUMBER}</td>
                      <td>
                        <i
                          onClick={() => {
                            setEditMember(true);
                            setAddMember(false);
                            setMember(members);
                            setMobileNumber(members.MOBILENUMBER);
                          }}
                          className="fas fa-edit"
                          style={{
                            color: "#009efb",
                          }}
                        ></i>
                      </td>
                      <td>
                        <i
                          onClick={async () => {
                            setDelUserConfirm(index);
                          }}
                          className="fas fa-trash"
                          style={{
                            color: "#009efb",
                            marginLeft: 16,
                            cursor: "pointer",
                          }}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Modal isOpen={delUserConfirm === -1 ? false : true}>
                <ModalHeader className="font-weight-bold text-uppercase">
                  Confirm Delete
                </ModalHeader>
                <ModalBody>
                  <p>
                    You are about to delete this user. Please note this cannot
                    be undone
                  </p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    className="btn"
                    color="primary"
                    size="md"
                    style={{ float: "right" }}
                    onClick={async () => {
                      await campaignService.deleteMember(
                        campaignGroup[delUserConfirm].SUBSCRIBERID
                      );
                      setCampaignGroup(
                        campaignGroup.length > 1
                          ? campaignGroup.filter(
                              (_, subIndex) => subIndex !== delUserConfirm
                            )
                          : []
                      );
                      setDelUserConfirm(-1);
                    }}
                  >
                    Confirm
                  </Button>
                  <Button
                    className="btn"
                    color="secondary"
                    size="md"
                    style={{ float: "right" }}
                    onClick={() => setDelUserConfirm(-1)}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </CardBody>
            <CardFooter>
              <CSVReader
                onUploadAccepted={(results) => {
                  setCsv({
                    isActive: true,
                    csv: {
                      numbers: [...results.data].join(",").split(","),
                      partnerID: localStorage.getItem("id"),
                      groupID: "16",
                    },
                  });
                }}
              >
                {({
                  getRootProps,
                  acceptedFile,
                  ProgressBar,
                  getRemoveFileProps,
                }) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        float: "left",
                      }}
                    >
                      <Button
                        type="button"
                        className="btn"
                        color="primary"
                        {...getRootProps()}
                      >
                        Upload CSV
                      </Button>
                      {acceptedFile ? (
                        <Button
                          {...getRemoveFileProps()}
                          className="btn ml-2"
                          color="secondary"
                        >
                          Remove
                        </Button>
                      ) : null}
                    </div>
                    <div style={{ marginTop: 20 }}>
                      {acceptedFile && acceptedFile.name}
                      <ProgressBar className="mb-2" />
                    </div>
                  </>
                )}
              </CSVReader>
              <Button
                className="btn"
                color="primary"
                size="md"
                style={{ float: "right", marginLeft: 10 }}
                onClick={() => (csv.isActive ? uploadCsv() : setList(false))}
              >
                {csv.isActive ? "Upload" : "Done"}
              </Button>
              <Button
                className="btn"
                color="secondary"
                size="md"
                style={{ float: "right", marginLeft: 10 }}
                onClick={() => {
                  setList(false);
                  setCsv({ ...csv, isActive: false });
                }}
              >
                Cancel
              </Button>
            </CardFooter>
          </>
        ) : (
          <>
            <CardTitle className="border-bottom p-3 mb-0 font-weight-bold text-uppercase">
              {addMember ? "Add Member" : "Edit Member"}
              <Button
                className="btn"
                color="primary"
                size="md"
                style={{ float: "right" }}
              >
                <i className="fas fa-user-plus"></i>{" "}
                {addMember ? "Add Member" : "Edit Member"}
              </Button>
            </CardTitle>
            <CardBody>
              <Formik
                initialValues={
                  addMember
                    ? {
                        groupID: "16",
                        firstName: "",
                        lastName: "",
                        emailAddress: "",
                        memberList: "",
                        type: "single",
                      }
                    : {
                        groupID: "16",
                        subscriberID: member.SUBSCRIBERID,
                        firstName: member.FIRSTNAME,
                        lastName: member.LASTNAME,
                        emailAddress: member.EMAILADDRESS,
                        mobileNumber: member.MOBILENUMBER,
                        active: "1",
                        deleted: "0",
                      }
                }
                enableReinitialize
                onSubmit={async (values) => {
                  setIsLoading(true);
                  console.log({
                    ...values,
                    mobileNumber: mobileNumber,
                    memberList: mobileNumber,
                  });
                  const resp = addMember
                    ? await campaignService.addMember({
                        ...values,
                        mobileNumber: mobileNumber,
                        memberList: mobileNumber,
                      })
                    : await campaignService.updateMember({
                        ...values,
                        mobileNumber: mobileNumber,
                      });
                  if (resp.success) {
                    setIsLoading(false);
                    setEditMember(false);
                    setAddMember(false);
                    initToast("success", resp.message);
                    onPageInit();
                  } else {
                    setIsLoading(false);
                    initToast("error", resp.message);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form className="row g-1" onSubmit={handleSubmit}>
                    <div className="col-6">
                      <label for="firstName">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        name="firstName"
                      />
                    </div>
                    <div className="col-6">
                      <label for="surname">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        name="lastName"
                      />
                    </div>
                    <div className="col-6 mt-2">
                      <label for="email">Email Address</label>
                      <input
                        type="text"
                        className="form-control"
                        id="emailAddress"
                        value={values.emailAddress}
                        onChange={handleChange}
                        name="emailAddress"
                      />
                    </div>
                    <div className="col-6 mt-2">
                      <label for="number">Mobile Number</label>
                      <PhoneInput
                        className="form-control"
                        id="mobileNumber"
                        name="mobileNumber"
                        defaultCountry="US"
                        style={{ display: "flex" }}
                        value={mobileNumber}
                        placeholder="Mobile Number"
                        onChange={(e) => setMobileNumber(e)}
                      />
                      {/* <input
                                                type="text"
                                                className="form-control"
                                                id="memberList"
                                                value={
                                                    addMember
                                                        ? values.memberList
                                                        : values.mobileNumber
                                                }
                                                onChange={handleChange}
                                                name="memberList"
                                            /> */}
                    </div>
                    <div className="p-3" style={{ width: "100%" }}>
                      <Button
                        className="btn"
                        color="primary"
                        size="md"
                        style={{
                          float: "right",
                          marginLeft: 10,
                        }}
                        onClick={handleSubmit}
                      >
                        {addMember ? "Add Member" : "Save"}
                      </Button>
                      <Button
                        className="btn btn-outline-primary "
                        size="md"
                        color="transparant"
                        style={{ float: "right" }}
                        onClick={() => {
                          setEditMember(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </CardBody>
          </>
        )}
      </Modal>
    </Card>
  );
}

export default ManageCampaign;
