import React, { useState, ReactDOM, useRef, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Switch, Route } from "react-router-dom";
import StoreList from "../../components/ProfileManager/StoreList";
import tempJpg from "../../assets/images/tempPdf.jpg";
import example from "../../assets/images/example.jpg";
import { QRCodeSVG } from "qrcode.react";
import { Card, CardBody, CardTitle, CardFooter, Button } from "reactstrap";
import { saveSvgAsPng } from "save-svg-as-png";
import qrService from "../../api/services/qrService";
import Loader from "../../components/loader/loader";
import SearchIcon from "../../components/icons/searchIcon";
import DownloadIcon from "../../components/icons/DownloadIcon";
import { Fancybox } from "@fancyapps/ui";
//import '@fancyapps/ui/dist/fancybox/fancybox.css';
import "./GenerateQrCode.css";

import example1 from "../../assets/images/qr/board.JPG";
import example2 from "../../assets/images/qr/list.JPG";
import partnerService from "../../api/services/partnerService";

function svgToImage(svgElement, callback) {
  try {
    // Создаем объект Blob из SVG элемента
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const svgBlob = new Blob([svgData], {
      type: "image/svg+xml;charset=utf-8",
    });
    const url = URL.createObjectURL(svgBlob);

    // Создаем изображение и загружаем URL Blob
    const img = new Image();
    img.onload = function () {
      // Создаем Canvas элемент
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      // Рисуем изображение на Canvas
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      // Получаем Data URL из Canvas
      const imgData = canvas.toDataURL("image/png");

      // Освобождаем память, удаляя объект URL
      URL.revokeObjectURL(url);

      // Возвращаем результат через callback
      callback(imgData);
    };
    img.src = url;
  } catch (e) {}
}
function drawTextOnImage(imageSrc, text, x, y, font, color, callback) {
  // Создаем изображение и загружаем URL
  const img = new Image();
  img.crossOrigin = "Anonymous"; // Для обхода проблем с CORS
  img.onload = function () {
    // Создаем Canvas элемент
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    // Рисуем изображение на Canvas
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    // Устанавливаем свойства текста
    ctx.font = font;
    ctx.fillStyle = color;

    // Рисуем текст
    ctx.fillText(text, x, y);

    // Получаем Data URL из Canvas
    const imgData = canvas.toDataURL("image/png");

    // Возвращаем результат через callback
    callback(imgData);
  };
  img.src = imageSrc;
}
function overlayImages(baseImgUrl, overlayImgUrl, settings, callback) {
  // Загружаем базовое изображение
  const baseImg = new Image();
  baseImg.crossOrigin = "Anonymous";
  baseImg.onload = function () {
    // Создаем Canvas для базового изображения
    const canvas = document.createElement("canvas");
    canvas.width = baseImg.width;
    canvas.height = baseImg.height;
    const ctx = canvas.getContext("2d");

    // Рисуем базовое изображение
    ctx.drawImage(baseImg, 0, 0);

    // Загружаем изображение для наложения
    const overlayImg = new Image();
    overlayImg.crossOrigin = "Anonymous";
    overlayImg.onload = function () {
      // Рисуем изображение для наложения поверх базового
      ctx.drawImage(
        overlayImg,
        settings.x,
        settings.y,
        settings.width,
        settings.height
      );

      // Получаем Data URL из Canvas
      const resultImgData = canvas.toDataURL("image/png");

      // Возвращаем результат через callback
      callback(resultImgData);
    };
    overlayImg.src = overlayImgUrl;
  };
  baseImg.src = baseImgUrl;
}

function GenerateQrCode() {
  const svgRef = useRef();
  const [qrLink, setQrLink] = useState("");
  const [isLoading, setIsLoading] = useState();

  const downloadSVG = () => {
    saveSvgAsPng(document.getElementById("qrCode"), "qrCode.png", {
      scale: 5,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getQrCode();
  }, []);

  const getQrCode = async () => {
    try {
      const resp = await qrService.getQrCode();
      if (resp.status) {
        setQrLink(resp.link);
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    Fancybox.bind('[data-fancybox="gallery"]', {
      // Настройки Fancybox
    });

    return () => {
      Fancybox.destroy();
    };
  }, []);
  const [combinedImageUrls, setCombinedImageUrls] = useState({});
  useEffect(() => {
    if (!isLoading && svgRef.current) {
      const resp = partnerService.getPartner().then((data) => {
        console.log("datadata", data.data[0]);
        const bannerImage = data.data[0].BANNERFILE;
        const titleStore = data.data[0].PARTNERNAME;

        svgToImage(svgRef.current?.querySelector("svg"), function (imgData) {
          const imageUrls = [1, 2, 3, 4, 5].map(
            (type) => `/images/qrGeneration/type/${type}.jpg`
          );
          const newCombinedImageUrls = {};

          imageUrls.forEach((url, index) => {
            overlayImages(
              url,
              imgData,
              {
                x: 340,
                y: 240,
                width: 150,
                height: 150,
              },
              function (resultImgData) {
                overlayImages(
                  resultImgData,
                  bannerImage,
                  {
                    x: 316,
                    y: 780,
                    width: 199,
                    height: 79,
                  },
                  function (bannerImgData) {
                    drawTextOnImage(
                      bannerImgData,
                      titleStore.toUpperCase(),
                      325,
                      800,
                      "500 12px Altivo",
                      "white",
                      function (imgData) {
                        newCombinedImageUrls[index + 1] = imgData;
                        if (
                          Object.keys(newCombinedImageUrls).length ===
                          imageUrls.length
                        ) {
                          setCombinedImageUrls(newCombinedImageUrls);
                        }
                      }
                    );
                  }
                );
              }
            );
          });
        });
      });
      console.log("resp,resp", resp);
    }
  }, [isLoading]);
  return (
    <div>
      <Loader isLoading={isLoading} />
      <Card>
        <CardTitle className="border-bottom p-3 mb-0 font-weight-bold text-uppercase">
          <i class="fas fa-qrcode mr-2"></i>
          Marketing Tools
        </CardTitle>
        <CardBody>
          <div>
            <div className="row">
              <div className="col-md-3 text-center">
                <div
                  style={{
                    maxWidth: "300px",
                    width: "100%",
                  }}
                >
                  <h5
                    style={{
                      color: "#5e53a3",
                    }}
                  >
                    Here is your unique QR code!
                  </h5>

                  <div
                    style={{
                      maxWidth: "300px",
                      width: "100%",
                      padding: "20px 15px",
                      background: "#f9edf3",
                    }}
                  >
                    <p>
                      Download your unique QR Code and display it in your
                      business and on your current marketing materials.
                      Customers will scan this to register in your database to
                      receive cash-back offers.
                    </p>

                    <div
                      style={{
                        padding: 20,
                        borderRadius: 20,
                      }}
                      //  ref={svgRef}
                    >
                      {isLoading ? null : (
                        <div ref={svgRef}>
                          <QRCodeSVG
                            height={200}
                            width={200}
                            id={"qrCode"}
                            value={qrLink}
                          />
                        </div>
                      )}
                    </div>
                    <div className={"qr-item__controls"}>
                      <a onClick={downloadSVG}>
                        <DownloadIcon />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-9" style={{ marginTop: 25 }}>
                <h3
                  style={{
                    color: "#5e53a3",
                    lineHeight: "28px",
                  }}
                >
                  Your business listing image is pre-populated in the leaflets
                  below, so start building out your database by downloading one
                  or more and displaying them in your business.
                </h3>
                <p>
                  Start building your database and increase customer engagement
                  by downloading and printing one or more of the marketing
                  pieces below. Then, strategically place them throughout your
                  business as counter cards, table cards, or an exterior
                  A-Board. Consider any visible area frequented by
                  customers—such as store windows, doors, table tops, or even
                  bathrooms—our eye-catching designs and messaging will draw
                  attention and encourage customer interaction.
                </p>
                <div className="row">
                  <div className="col-md-12">
                    <div className="qr-items">
                      <div className="qr-item">
                        {combinedImageUrls && (
                          <img
                            src={combinedImageUrls[1]}
                            alt="Combined with QR Code"
                          />
                        )}
                        <div className={"qr-item__controls"}>
                          <a
                            data-fancybox="gallery"
                            href={combinedImageUrls[1]}
                          >
                            <SearchIcon />
                          </a>
                          <a download href={combinedImageUrls[1]}>
                            <DownloadIcon />
                          </a>
                        </div>
                      </div>
                      <div className="qr-item">
                        {combinedImageUrls && (
                          <img
                            src={combinedImageUrls[2]}
                            alt="Combined with QR Code"
                          />
                        )}
                        <div className={"qr-item__controls"}>
                          <a
                            data-fancybox="gallery"
                            href={combinedImageUrls[2]}
                          >
                            <SearchIcon />
                          </a>
                          <a download href={combinedImageUrls[2]}>
                            <DownloadIcon />
                          </a>
                        </div>
                      </div>
                      <div className="qr-item">
                        {combinedImageUrls && (
                          <img
                            src={combinedImageUrls[3]}
                            alt="Combined with QR Code"
                          />
                        )}
                        <div className={"qr-item__controls"}>
                          <a
                            data-fancybox="gallery"
                            href={combinedImageUrls[3]}
                          >
                            <SearchIcon />
                          </a>
                          <a download href={combinedImageUrls[3]}>
                            <DownloadIcon />
                          </a>
                        </div>
                      </div>
                      <div className="qr-item">
                        {combinedImageUrls && (
                          <img
                            src={combinedImageUrls[4]}
                            alt="Combined with QR Code"
                          />
                        )}
                        <div className={"qr-item__controls"}>
                          <a
                            data-fancybox="gallery"
                            href={combinedImageUrls[4]}
                          >
                            <SearchIcon />
                          </a>
                          <a download href={combinedImageUrls[4]}>
                            <DownloadIcon />
                          </a>
                        </div>
                      </div>
                      <div className="qr-item">
                        {combinedImageUrls && (
                          <img
                            src={combinedImageUrls[5]}
                            alt="Combined with QR Code"
                          />
                        )}
                        <div className={"qr-item__controls"}>
                          <a
                            data-fancybox="gallery"
                            href={combinedImageUrls[5]}
                          >
                            <SearchIcon />
                          </a>
                          <a download href={combinedImageUrls[5]}>
                            <DownloadIcon />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-8">
                <h5
                  style={{
                    color: "#5e53a3",
                  }}
                >
                  Use Example: Counter/Table Cards
                </h5>
                <div className="row">
                  <div className="col-12">
                    <img
                      src={example2}
                      alt="A-Board Example 1"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-4">
                <h5
                  style={{
                    color: "#5e53a3",
                  }}
                >
                  Use Example: Exterior A-Board
                </h5>
                <div className="row">
                  <div className="col-12">
                    <img
                      src={example1}
                      alt="A-Board Example 2"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*   <Row>
                        <Col
                            sm={4}
                            lg={4}
                            style={{ borderRight: "1px solid #e9ecef" }}
                        >
                            <p>
                                Download and print the QR Code for display in
                                your business and on your current marketing
                                materials.
                            </p>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <div
                                    style={{
                                        padding: 20,
                                        borderRadius: 20,
                                    }}
                                    ref={svgRef}
                                >
                                    {isLoading ? null : (
                                        <QRCodeSVG
                                            height={200}
                                            width={200}
                                            ref={svgRef}
                                            id={"qrCode"}
                                            value={qrLink}
                                        />
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col sm={4} lg={4} pl={2}>
                            <p>
                                Download and print for applying to counter
                                cards, table cards, or anywhere in your business
                                your customers can see it most. (Hint: Store
                                window, doors or bathrooms, etc.)
                            </p>
                            <img style={{ height: "60vh" }} src={tempJpg}></img>
                        </Col>
                        <Col sm={4} lg={4}>
                            <img style={{ width: "100%" }} src={example}></img>
                        </Col>
                    </Row>*/}
        </CardBody>
        <CardFooter>
          {/*                    <Button
                        style={{ float: "right" }}
                        color="btn btn-primary "
                        onClick={downloadSVG}
                    >
                        Download QR Code
                    </Button>
                    <a download="qr-flyer.jpg" href={tempJpg} title="ImageName" >
                        <Button
                            style={{ float: "right", marginRight: 20 }}
                            color="btn btn-primary "
                            onClick={() => {}}
                        >
                            Download QR Flyer
                        </Button>
                    </a>*/}
        </CardFooter>
      </Card>
    </div>
  );
}

export default GenerateQrCode;
