import * as api from "..";
import { PARTNER } from "../config/endpoints";

interface PartnerService {
  getPartner: (body: {}) => Promise<any>;
  updatePartner: (body: {}) => Promise<any>;
  resetPin: (body: {}) => Promise<any>;
  buyCredits: (body: {}) => Promise<any>;
  verifyToken: () => Promise<any>;
}

const partnerService: PartnerService = {
  getPartner: async () => {
    const resp = await api.get(PARTNER.getPartner);
    return resp;
  },
  updatePartner: async (body) => {
    const resp = await api.post(PARTNER.updatePartner, body);
    return resp;
  },
  resetPin: async (body) => {
    const resp = await api.post(PARTNER.resetPin, body);
    return resp;
  },
  verifyToken: async () => {
    const resp = await api.get(PARTNER.verifyToken);
    return resp;
  },
  buyCredits: async (body) => {
    const resp = await api.post(PARTNER.buyCredits, body);
    return resp;
  },
};

export default partnerService;
