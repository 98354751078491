import React, { useState } from 'react';
import {
    Row,
    Col
} from 'reactstrap';
import { Switch, Route } from 'react-router-dom';

import Profile from '../../components/ProfileManager/Profile'
import StoreList from '../../components/ProfileManager/StoreList'


function ProfileManager() {

    return (
        <div>
            <Row>
                <Col sm={12}>
                    <Profile />
                </Col>
            </Row>
        </div>
    )
}

export default ProfileManager