import "./BankingDetails.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, CardFooter } from "reactstrap";
import StripeLogo from "../../assets/images/stripe-logo.png";
import Loader from "../loader/loader";
import bankService from "../../api/services/bankService";

const BankingDetails = () => {
  const [isLoading, setIsLoading] = useState();
  const [showLoginLink, setShowLoginLink] = useState("");
  const getStripeLoginLink = async () => {
    try {
      const resp = await bankService.getStripeLoginLink();
      if (resp && resp.success) {
        setShowLoginLink(resp.data.url);
      }
    } catch (error) {}
  };

  const onSubmit = async () => {
    setIsLoading(true);
    if (showLoginLink) {
      setIsLoading(false);
      window.open(showLoginLink, "_blank");
      return;
    }
    try {
      const respConnectStripLink = await bankService.connectStripeLink(
        "https://dashboard.stndaprt.com/#/payment-details",
        "https://dashboard.stndaprt.com/#/payment-details"
      );
      setIsLoading(false);
      window.open(respConnectStripLink.data.url, "_blank");
    } catch (error) {}
  };

  useEffect(() => {
    // getBankDetails();
    getStripeLoginLink();
  }, []);

  return (
    <Card>
      <CardTitle className="border-bottom p-3 mb-0 font-weight-bold text-uppercase">
        Payout Details
      </CardTitle>
      <CardBody>
        <div className="col-6 p-0">
          <div
            className=" pb-3 mb-0 "
            style={{ color: "#4463b4", fontSize: 24, fontWeight: "600" }}
          >
            Bank Account Information
          </div>
        </div>
        <p
          className="border-bottom"
          style={{
            color: "#858684",
            paddingBottom: "25px",
          }}
        >
          Stripe Connect is the core Stripe product for Marketplaces and
          Platforms like STND APRT.
          <br />
          It allows us to take a payment from a customer and split it between
          several other recipients. Register your business or use
          <br />
          your current Stripe account below to begin receiving payments.
        </p>

        <Loader isLoading={isLoading} />
        <div className="row" style={{ position: "relative" }}>
          <div
            className="col-6"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              paddingRight: 100,
              marginTop: 76,
              marginBottom: 160,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p
                className="mt-3 "
                style={{
                  color: "#000000",
                  fontSize: "36px",
                  fontWeight: 600,
                  lineHeight: "120%",
                  marginRight: 32,
                }}
              >
                STND APRT <br /> partners with <br />
                Stripe for secure
                <br /> payments
              </p>
              <img
                src={StripeLogo}
                style={{
                  height: 50,
                  width: 330,
                  marginTop: 28,
                  marginLeft: -4,
                }}
              />
            </div>
          </div>
          <div
            className="col-6"
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 76,
            }}
          >
            <p
              className="mt-3 "
              style={{
                color: "#000000",
                fontSize: "36px",
                fontWeight: 600,
                lineHeight: "120%",
                marginRight: 32,
              }}
            >
              Get started with Stripe
            </p>
            <p
              style={{
                color: "#858684",
                paddingBottom: "25px",
                fontSize: "18px",
              }}
            >
              If you’re completing this form on behalf of a business,
              <br /> it must be completed by the business owner or
              <br /> someone with significant management responsibility
              <br /> of that business.
            </p>
            <button
              type="submit"
              onClick={onSubmit}
              className="btn btn-primary mt-3 "
              style={{ width: 500 }}
            >
              Configure your Stripe Connect Account to Receive Payments
            </button>
          </div>
          <img
            src={require("../../assets/images/leftStrap.png")}
            style={{
              width: 257,
              height: 180,
              position: "absolute",
              top: 13,
              left: -6,
            }}
          />
          <img
            src={require("../../assets/images/rightStrap.png")}
            style={{
              width: 400,
              height: 180,
              position: "absolute",
              right: -16,
              bottom: -30,
            }}
          />
        </div>
      </CardBody>
      <CardFooter style={{ backgroundColor: "#fff" }}>
        <div style={{ textAlign: "center" }}>
          Companies that trust Stripe Connect
        </div>
        <div
          className="row"
          style={{ justifyContent: "center", marginTop: -44 }}
        >
          <img
            src={require("../../assets/images/companyLogo1.png")}
            style={{
              width: 160,
              height: 160,
              objectFit: "contain",
            }}
          />
          <img
            src={require("../../assets/images/companyLogo2.png")}
            style={{
              width: 160,
              height: 160,
              objectFit: "contain",
            }}
          />
          <img
            src={require("../../assets/images/companyLogo3.png")}
            style={{
              width: 160,
              height: 160,
              objectFit: "contain",
            }}
          />
          <img
            src={require("../../assets/images/companyLogo4.png")}
            style={{
              width: 160,
              height: 160,
              objectFit: "contain",
            }}
          />
          <img
            src={require("../../assets/images/companyLogo5.png")}
            style={{
              width: 160,
              height: 160,
              objectFit: "contain",
            }}
          />
          <img
            src={require("../../assets/images/companyLogo6.png")}
            style={{
              width: 160,
              height: 160,
              objectFit: "contain",
            }}
          />
          <img
            src={require("../../assets/images/companyLogo7.png")}
            style={{
              width: 160,
              height: 160,
              objectFit: "contain",
            }}
          />
        </div>
      </CardFooter>
    </Card>
  );
};

export default BankingDetails;
