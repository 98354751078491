import * as api from "..";
import { STAFF } from "../config/endpoints";

interface StaffService {
  updateStaff: (body: {}) => Promise<any>;
  deleteStaff: (staffUID: string) => Promise<any>;
  getAllStaff: (body: {}) => Promise<any>;
  addStaffMember: (body: {}) => Promise<any>;
  getStaffMember: (body: {}) => Promise<any>;
}

const staffService: StaffService = {
  updateStaff: async (body) => {
    const resp = await api.post(STAFF.updateStaff, body);
    return resp;
  },
  deleteStaff: async (staffUID) => {
    const resp = await api.deleteCall(`${STAFF.deleteStaff}${staffUID}`);
    return resp;
  },
  getAllStaff: async () => {
    const resp = await api.get(STAFF.getAllStaff);
    return resp;
  },
  addStaffMember: async (body) => {
    const resp = await api.post(STAFF.addStaff, body);
    return resp;
  },
  getStaffMember: async (body) => {
    const resp = await api.get(STAFF.getStaffMember + body);
    return resp;
  },
};

export default staffService;
