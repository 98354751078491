import * as api from "..";
import { BANK } from "../config/endpoints";

interface bankService {
  getBankDetails: () => Promise<any>;
  getStripeLoginLink: () => Promise<any>;
  updateBankDetails: (id: string) => Promise<any>;
  connectStripeLink: (refresh_url: string, return_url: string) => Promise<any>;
}

const bankService: bankService = {
  getBankDetails: async () => {
    const resp = await api.get(BANK.getBank);
    return resp;
  },
  getStripeLoginLink: async () => {
    const resp = await api.get(BANK.stripeLoginLink);
    return resp;
  },
  connectStripeLink: async (refresh_url: string, return_url: string) => {
    const resp = await api.post(BANK.stripeaccountLink, {
      refresh_url,
      return_url,
    });
    return resp;
  },
  updateBankDetails: async (body: {}) => {
    const resp = await api.post(BANK.updateBank, body);
    return resp;
  },
};

export default bankService;
