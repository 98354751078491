import React from 'react'

const Loader = ({isLoading}) => {

    return (
        <div className={isLoading ? 'loader' : 'none'}>
            <div className="overlay">
                <div className="overlay__inner">
                    <div className="overlay__content">
                        <span className="spinner"></span>
                    </div>
                </div>
            </div>
        </div>
    );
    };
    export default Loader;
