import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Nav, Navbar, NavbarBrand, Collapse } from "reactstrap";
import logolighttext from "../../../assets/images/logo-purple.png";
import profilephoto from "../../../assets/images/background/navImg.png";
import { AuthContext } from "../../../context/authProvider";
import partnerService from "../../../api/services/partnerService";
import Loader from "../../../components/loader/loader";
import OutsideClickHandler from "react-outside-click-handler";
import { addEventListener } from "../../../helper/eventEmitter";
import { ToastContext } from "../../../context/toastProvider";

const Header = () => {
  const { userState } = useContext(AuthContext);
  const { initToast } = useContext(ToastContext);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [credits, setCredits] = useState(0);
  const [selectedCredits, setSelectedCredits] = useState("");

  const logout = () => {
    localStorage.clear();
    history.push("/login");
  };
  const showMobilemenu = () => {
    document.getElementById("main-wrapper").classList.toggle("show-sidebar");
  };

  useEffect(() => {
    addEventListener("recall-sms-credits", () => {
      setTimeout(() => {
        initCredits();
      }, 5000);
      setTimeout(() => {
        initCredits();
      }, 15000);
      setTimeout(() => {
        initCredits();
      }, 10000);
      setTimeout(() => {
        initCredits();
      }, 20000);
      setTimeout(() => {
        initCredits();
      }, 25000);
    });
    addEventListener("logout", () => {
      logout();
    });
  }, []);

  useEffect(() => {
    initCredits();
  }, [userState]);

  const initCredits = async () => {
    try {
      const resp = await partnerService.getPartner();
      if (resp?.data && resp?.data?.length > 0) {
        setCredits(resp?.data[0]?.CAMPAIGNCREDITS);
      }
    } catch (error) {}
  };

  const onBuyFromCard = async () => {
    if (selectedCredits) {
      try {
        setLoading(true);
        const resp = await partnerService.buyCredits({
          credits: selectedCredits,
          checkout: 1,
          success_url: "https://dashboard.stndaprt.com/#/process-voucher",
          cancel_url: "https://dashboard.stndaprt.com/#/process-voucher",
        });
        setLoading(false);
        if (resp.success && resp?.url) {
          window.open(resp?.url, "_self");
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <header className="topbar navbarbg" data-navbarbg="skin1">
      <Navbar className="top-navbar" dark expand="md">
        <div className="navbar-header" id="logobg" data-logobg="skin6">
          <NavbarBrand href="/">
            <span className="logo-text">
              <img src={logolighttext} alt="homepage" className="dark-logo" />
            </span>
          </NavbarBrand>
          <button
            className="btn btn-link nav-toggler d-block d-md-none"
            onClick={() => showMobilemenu()}
          >
            <i className="fas fa-bars" />
          </button>
        </div>
        <Collapse className="navbarbg" navbar data-navbarbg="skin1">
          <Nav
            className="ml-auto mr-5 float-right"
            style={{ alignItems: "center" }}
            navbar
          >
            {localStorage.getItem("token") ? (
              <>
                {" "}
                <h5 className="mr-3 mb-0 text-primary">
                  <span className="font-weight-light text-muted">
                    Logged in as:{" "}
                  </span>{" "}
                  {localStorage.getItem("name")}
                </h5>
                <img
                  src={profilephoto}
                  alt="user"
                  className="rounded-circle"
                  width="31"
                />{" "}
                <h5 className="mr-3 mb-0 text-primary ml-3">
                  {credits}
                  <span className="font-weight-light text-muted ">
                    {"  "}Campaign Credits Available
                  </span>
                </h5>
                <button
                  className="btn btn-primary"
                  style={{ fontSize: 13 }}
                  onClick={() => setShowModal(!showModal)}
                >
                  Buy SMS Credits
                </button>
                <h1 className="m-0 text-muted ml-2 font-weight-light">|</h1>
                <span className="font-weight-bold text-muted ml-2">Logout</span>
                <Link
                  to={"./login"}
                  onClick={() => logout()}
                  className="logout-link ml-2"
                >
                  <i className=" fas fa-power-off" />
                </Link>
              </>
            ) : (
              ""
            )}
            <OutsideClickHandler
              onOutsideClick={() => {
                showModal && setShowModal(false);
              }}
            >
              <div
                style={{
                  position: "fixed",
                  top: 88,
                  right: 88,
                  width: 495,
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#FAFAF9",
                }}
                className={`fading-component ${showModal ? "visible" : ""}`}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#00b8da",
                    position: "relative",
                  }}
                >
                  <span
                    style={{
                      color: "#FFFFFF",
                      fontSize: 24,
                      fontWeight: 500,
                      marginLeft: 16,
                      marginRight: 16,
                      paddingTop: 8,
                      paddingBottom: 8,
                    }}
                  >
                    Top up your SMS credits
                  </span>
                  <img
                    src={require("../../../assets/images/cross.png")}
                    style={{
                      position: "absolute",
                      width: 28,
                      height: 28,
                      right: 16,
                      filter: "brightness(0) invert(1)",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowModal(false)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 28,
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      borderStyle: "solid",
                      backgroundColor:
                        selectedCredits === "500" ? "#7460ee" : "#FAFAF9",
                      borderColor:
                        selectedCredits === "500" ? "#7460ee" : "#adadad",
                      color: selectedCredits === "500" ? "#FFFFFF" : "#7b7b7b",
                      borderWidth: 2,
                      textAlign: "center",
                      width: 118,
                      fontSize: 22,
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: 8,
                      paddingBottom: 8,
                      cursor: "pointer",
                    }}
                    onClick={() => setSelectedCredits("500")}
                  >
                    <span style={{ fontSize: 32 }}>500</span>
                    <span style={{ marginTop: -10 }}>credits</span>
                    <span style={{ fontSize: 26, marginTop: 8 }}>$25</span>
                  </div>
                  <div
                    style={{
                      borderStyle: "solid",
                      backgroundColor:
                        selectedCredits === "750" ? "#7460ee" : "#FAFAF9",
                      borderColor:
                        selectedCredits === "750" ? "#7460ee" : "#adadad",
                      color: selectedCredits === "750" ? "#FFFFFF" : "#7b7b7b",
                      borderWidth: 2,
                      textAlign: "center",
                      width: 118,
                      fontSize: 22,
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: 8,
                      paddingBottom: 8,
                      marginLeft: 16,
                      marginRight: 16,
                      cursor: "pointer",
                    }}
                    onClick={() => setSelectedCredits("750")}
                  >
                    <span style={{ fontSize: 32 }}>750</span>
                    <span style={{ marginTop: -10 }}>credits</span>
                    <span style={{ fontSize: 26, marginTop: 8 }}>$37.50</span>
                  </div>
                  <div
                    style={{
                      borderStyle: "solid",
                      backgroundColor:
                        selectedCredits === "1000" ? "#7460ee" : "#FAFAF9",
                      borderColor:
                        selectedCredits === "1000" ? "#7460ee" : "#adadad",
                      color: selectedCredits === "1000" ? "#FFFFFF" : "#7b7b7b",
                      borderWidth: 2,
                      textAlign: "center",
                      width: 118,
                      fontSize: 22,
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: 8,
                      paddingBottom: 8,
                      cursor: "pointer",
                    }}
                    onClick={() => setSelectedCredits("1000")}
                  >
                    <span style={{ fontSize: 32 }}>1000</span>
                    <span style={{ marginTop: -10 }}>credits</span>
                    <span style={{ fontSize: 26, marginTop: 8 }}>$50</span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 30,
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  {isLoading ? (
                    <Loader isLoading />
                  ) : (
                    <button
                      style={{
                        width: 340,
                        backgroundColor: "#009693",
                        borderColor: "#00AAA9",
                        color: "#ffffff",
                      }}
                      className="btn"
                      onClick={onBuyFromCard}
                    >
                      Select the number of credits
                      <br />
                      you'd like and click here to purchase
                    </button>
                  )}
                </div>
              </div>
            </OutsideClickHandler>
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  );
};
export default Header;
