import React, { useState, useContext, useEffect, useRef } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import Switch from "react-switch";
import { ToastContext } from "../../context/toastProvider";
import Loader from "../loader/loader";
import Dropzone from "react-dropzone";
import campaignService from "../../api/services/campaignService";
import DatePicker from "react-datepicker";
import Cropper from "react-cropper";
import InfoIcon from "../icons/InfoIcon";

const EditCampaign = ({ match, params }) => {
  const [isLoading, setIsLoading] = useState();
  const { initToast } = useContext(ToastContext);
  const [banner, setBanner] = useState();
  const [tempBanner, setTempBanner] = useState();
  const [campaign, setCampaign] = useState({});
  const [fileBanner, setFileBanner] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const history = useHistory();
  const [active, setActive] = useState(true);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileReadBanner = async (event, drag) => {
    if (drag) var file = event;
    else var file = event.target.files[0];
    const base64 = await convertBase64(file);
    console.log(base64);
    setBanner(base64.replace(/^data:image\/[a-z]+;base64,/, ""));
  };

  useEffect(() => {
    getCampaign();
  }, []);

  const getCampaign = async () => {
    setIsLoading(true);
    try {
      const resp = await campaignService.getCampaigns(match.params.id);
      if (resp.code === 0) {
        let temp = resp.campaignList.find(
          (o) => o.CAMPAIGNID == match.params.id
        );
        console.log(temp);
        setBanner(temp.CAMPAIGNBANNER);
        setStartDate(new Date(temp.STARTDATE));
        setEndDate(new Date(temp.ENDDATE));
        setCampaign(temp);
        {
          temp.ACTIVE == "1" ? setActive(true) : setActive(false);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [editImage, setEditImage] = useState(false);
  const cropperRef = useRef(null);
  const onSaveCrop = async (e) => {
    e.preventDefault();
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    // console.log(cropper.getCroppedCanvas().toDataURL());
    const base64 = cropper.getCroppedCanvas().toDataURL();
    setBanner(base64.replace(/^data:image\/[a-z]+;base64,/, ""));

    setFileBanner(cropper.getCroppedCanvas().toDataURL());
    setTempBanner(cropper.getCroppedCanvas().toDataURL());
    setEditImage(false);
  };

  const onClearCrop = (e) => {
    e.preventDefault();
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (cropper) {
      cropper.clear();
    }
    setFileBanner(null);
    setTempBanner(null);
    setBanner(null);
    setEditImage(false);
    // console.log(cropper.getCroppedCanvas().toDataURL());
  };

  return (
    <Card>
      <CardTitle className="border-bottom p-3 mb-0 font-weight-bold text-uppercase">
        Edit Campaign
      </CardTitle>
      <CardBody>
        <Loader isLoading={isLoading} />
        <Formik
          initialValues={{
            campaignName: campaign ? campaign.CAMPAIGNNAME : "",
            give_back_campaign: campaign ? campaign.GIVEBACKPERCENT : "",
            campaignDescription: campaign ? campaign.CAMPAIGNDESCRIPTION : "",
            minimumValue: campaign ? campaign.MINIMUMVALUE : "",
            startDate: campaign ? campaign.STARTDATE : "",
            endDate: campaign ? campaign.ENDDATE : "",
            campaignIntro: campaign ? campaign.CAMPAIGNINTRO : "",
            campaignSMSText: campaign ? campaign.CAMPAIGNSMSTEXT : "",
          }}
          enableReinitialize
          onSubmit={async (values) => {
            if (values.give_back_campaign < 15) {
              initToast(
                "error",
                "To build loyalty the minimum Give-back Percentage should be 15% or higher"
              );
            } else {
              setIsLoading(true);
              try {
                const resp = await campaignService.updateCampaign({
                  ...values,
                  campaignBanner: banner ? banner : "",
                  endDate: endDate ? endDate : campaign.ENDDATE,
                  startDate: startDate ? startDate : campaign.STARTDATE,
                  active: active,
                  deleted: "0",
                  campaignID: String(match.params.id),
                });
                if (resp.success) {
                  setIsLoading(false);
                  history.goBack();
                  initToast("success", resp.message);
                } else {
                  setIsLoading(false);
                  initToast("error", resp.message);
                }
              } catch (error) {
                setIsLoading(false);
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className="row g-2" onSubmit={handleSubmit}>
              <div className="col-6">
                <div className={"info-input"}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Title of offer for listing card"
                    id="campaignName"
                    value={values.campaignName}
                    onChange={handleChange}
                    name="campaignName"
                  />
                  <div className="info-input__icon">
                    <InfoIcon />
                  </div>
                  <div className="info-input__img">
                    <img src="/images/infoCampaign/1.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-3">
                <DatePicker
                  onChange={(date) => setStartDate(date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Start Date"
                  selected={startDate}
                  className="form-control"
                />
              </div>
              <div className="col-3 ">
                <DatePicker
                  onChange={(date) => setEndDate(date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="End Date"
                  selected={endDate}
                  className="form-control"
                />
              </div>
              <div className="col-6 mt-3">
                <div className={"info-input"}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Offer headline for 'Buy' card"
                    id="campaignIntro"
                    value={values.campaignIntro}
                    onChange={handleChange}
                    name="campaignIntro"
                  />
                  <div className="info-input__icon">
                    <InfoIcon />
                  </div>
                  <div className="info-input__img">
                    <img src="/images/infoCampaign/2.jpg" alt="" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className={"info-input"}>
                      <input
                        className="form-control mt-3"
                        placeholder="Numeric purchase amount"
                        id="minimumValue"
                        type="number"
                        value={values.minimumValue}
                        onChange={handleChange}
                        name="minimumValue"
                      />
                      <div className="info-input__icon">$</div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={"info-input"}>
                      <input
                        className="form-control mt-3"
                        placeholder="Give-Back percentage (15%+)"
                        id="give_back_campaign"
                        type="number"
                        value={values.give_back_campaign}
                        onChange={handleChange}
                        name="give_back_campaign"
                      />
                      <div className="info-input__icon" style={{ right: 43 }}>
                        %
                      </div>
                      <div className="info-input__icon">
                        <InfoIcon />
                      </div>
                      <div
                        className="info-input__img"
                        style={{
                          top: -17,
                        }}
                      >
                        <img
                          src={require("../../assets/images/intro3.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"info-input"}>
                  <textarea
                    maxLength="250"
                    className="form-control mt-3"
                    placeholder="Offer Description"
                    id="campaignDescription"
                    style={{ minHeight: "150px" }}
                    type="textarea"
                    name="campaignDescription"
                    value={values.campaignDescription}
                    onChange={handleChange}
                  />
                  <p>{values?.campaignDescription?.length}/250</p>
                  <div className="info-input__icon">
                    <InfoIcon />
                  </div>
                  <div className="info-input__img">
                    <img src="/images/infoCampaign/3.jpg" alt="" />
                  </div>
                </div>
                <textarea
                  className="form-control mt-3"
                  placeholder="Campaigns SMS message: (be sure to include your business name so your customers know it’s from you)"
                  id="campaignSMSText"
                  maxLength="120"
                  style={{ minHeight: "100px" }}
                  type="textarea"
                  name="campaignSMSText"
                  value={values.campaignSMSText}
                  onChange={handleChange}
                />
                <p>{values?.campaignSMSText?.length}/120</p>
              </div>

              <div className="col-6 mt-3">
                <label htmlFor="banner">
                  Banner (Your banner will be resized to 600px x 300px)
                </label>

                <div className="col-12 p-0">
                  {editImage ? (
                    <>
                      <Cropper
                        src={tempBanner}
                        style={{ height: 400, width: 600 }}
                        aspectRatio={6 / 3}
                        guides={false}
                        ref={cropperRef}
                      />
                      <div style={{ dispay: "flex", margin: "30px 0" }}>
                        <button
                          onClick={(e) => onClearCrop(e)}
                          className="btn btn-primary mt-3 mr-2 "
                          style={{ float: "left" }}
                        >
                          Clear
                        </button>
                        <button
                          onClick={(e) => onSaveCrop(e)}
                          className="btn btn-primary mt-3 "
                          style={{ float: "left" }}
                        >
                          Save Image
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mb-0 col-12 p-0">
                        <img
                          src={
                            tempBanner ? tempBanner : campaign.CAMPAIGNBANNER
                          }
                          alt={`${campaign.CAMPAIGNNAME} Banner`}
                          style={{
                            width: "100%",
                            minHeight: 150,
                            maxHeight: 300,
                            borderRadius: "10px",
                            objectFit: "cover",
                          }}
                        />
                        <section className="container overlayControl">
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              setFileBanner(
                                acceptedFiles.map((file) => {
                                  Object.assign(file, {
                                    preview: URL.createObjectURL(file),
                                  });
                                  handleFileReadBanner(file, true);
                                })
                              );
                              setTempBanner(acceptedFiles[0].preview);
                              setFileBanner(acceptedFiles[0].preview);
                              setEditImage(true);
                            }}
                            name="logoFile"
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                {...getRootProps({
                                  className: "dropzone overlayControl",
                                })}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <input
                                  id="banner"
                                  {...getInputProps()}
                                  onChange={(e) => {
                                    handleFileReadBanner(e, false);
                                    console.log(e.target.files);
                                    setTempBanner(
                                      URL.createObjectURL(e.target.files[0])
                                    );
                                    setEditImage(true);
                                  }}
                                />
                                <div className="perfectCenter">
                                  <i className="fas fa-upload" />
                                  <p className="">
                                    Drag and Drop your image or click to upload
                                  </p>
                                  <p className="">
                                    Using copyrighted images without permission
                                    is illegal, so ensure you use original, or
                                    royalty-free images for your campaigns.
                                  </p>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </section>
                      </div>
                      {/* <div className="col-6 mt-3">
                        <label htmlFor="active">Active Campaign</label>
                        <div>
                          <Switch
                            onChange={() => setActive(!active)}
                            checked={active}
                            onColor={"#7460ee"}
                            checkedIcon={false}
                            uncheckedIcon={false}
                          />
                        </div>
                        <div>
                          Only activate if you want this campaign visible.{" "}
                          <br />
                          Maximum of two (2) active campaign allowed.
                        </div>
                      </div> */}
                    </>
                  )}
                </div>

                <button
                  type="submit"
                  className="btn btn-primary mt-3 "
                  style={{ float: "right" }}
                >
                  Save Campaign
                </button>
                <Link
                  to={"/campaign-manager"}
                  className="btn btn-secondary mt-3 mr-2"
                  style={{ float: "right" }}
                >
                  Cancel
                </Link>
              </div>
            </form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default EditCampaign;
