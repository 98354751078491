import React from 'react';
import logo from '../../../assets/images/poweredBy.svg';


const Footer = () => {
    return (
        <footer className="footer text-center">

        </footer>
    );
}
export default Footer;
