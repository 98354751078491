import * as api from '..';
import { PARTNER, QRCODE } from '../config/endpoints';

interface QrService {
    getQrCode: () => Promise<any>;

}

const qrService: QrService = {
    getQrCode: async () => {
        const resp = await api.get(QRCODE.getQrCode);
        return resp;
    },

};

export default qrService;
