import React, { useEffect, useState, useContext } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import staffService from "../../api/services/staffService";
import Loader from "../loader/loader";
import Switch from "react-switch";
import { ToastContext } from "../../context/toastProvider";

const EditUser = ({ match }) => {
  const [isLoading, setIsLoading] = useState();
  const [user, setUser] = useState();
  const [active, setActive] = useState(false);
  const history = useHistory();
  const { initToast } = useContext(ToastContext);

  useEffect(() => {
    onPageInit();
  }, []);

  const onPageInit = async () => {
    setIsLoading(true);
    try {
      const resp = await staffService.getStaffMember(match.params.id);
      if (resp.success === 0) {
        let temp = resp.staffInfo.find((o) => o.STAFFUID == match.params.id);
        setActive(temp.ACTIVE === 1 ? true : false);
        setUser(temp);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <Loader isLoading={isLoading} />
      <CardTitle className="border-bottom p-3 mb-0 font-weight-bold text-uppercase">
        <i class="fas fa-user-edit"></i>
        {user ? `Edit User - ${user.FIRSTNAME} ${user.LASTNAME}` : "Edit User"}
      </CardTitle>
      <CardBody>
        <Formik
          initialValues={{
            firstName: user ? user.FIRSTNAME : "",
            lastName: user ? user.LASTNAME : "",
            emailAddress: user ? user.EMAILADDRESS : "",
            mobileNumber: user ? user.MOBILENUMBER : "",
            staffUID: user ? user.STAFFUID : "",
            roleID: user ? user.ROLEID : "",
            storeID: "29",
          }}
          enableReinitialize
          onSubmit={async (values) => {
            setIsLoading(true);
            try {
              const resp = await staffService.updateStaff({
                ...values,
                active: active ? "1" : "0",
              });
              if (resp.success) {
                setIsLoading(false);
                history.goBack();
                initToast("success", resp.message);
              } else {
                setIsLoading(false);
                initToast("error", resp.message);
              }
            } catch (error) {
              setIsLoading(false);
            }
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form className="row g-1" onSubmit={handleSubmit}>
              <div className="col-6">
                <label for="firstName">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  name="firstName"
                />
              </div>
              <div className="col-6">
                <label for="surname">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  name="lastName"
                />
              </div>
              <div className="col-6 mt-2">
                <label for="email">Email Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="emailAddress"
                  value={values.emailAddress}
                  onChange={handleChange}
                  name="emailAddress"
                />
              </div>
              <div className="col-6 mt-2">
                <label for="roleID">Role</label>
                <select
                  className="form-control"
                  aria-label="Select A Charity"
                  id="roleID"
                  name="roleID"
                  onChange={handleChange}
                >
                  <option value="1">Admin</option>
                  <option value="2">Staff</option>
                </select>
              </div>
              <div className="col-6 mt-2">
                <label for="number">Mobile Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="mobileNumber"
                  value={values.mobileNumber}
                  onChange={handleChange}
                  name="mobileNumber"
                />
              </div>
              <div className="col-6 mt-3">
                <label for="active">Active Staff Member</label>
                <div>
                  <Switch
                    onChange={() => setActive(!active)}
                    checked={active}
                    onColor={"#7460ee"}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
              <div className="col-12" style={{ marginTop: "1.6em" }}>
                <button type="submit" className="btn btn-primary">
                  Update
                </button>
                <Link to="/user-manager">
                  <button className="ml-3 btn btn-outline-secondary">
                    Back
                  </button>
                </Link>
              </div>
            </form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default EditUser;
