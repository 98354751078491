import React from "react";
import { Row, Col } from "reactstrap";
import ValidateVoucher from "../../components/ProcessVoucher/ValidateVoucher";
import RevenueAndFeed from "./RevenueAndFeed";
import VoucherContextProvider from "../../context/ValidateVoucherContext";

export default function ProcessVoucher() {
  return (
    <div>
      <VoucherContextProvider>
        <Row>
          <Col sm={12}>
            <ValidateVoucher />
          </Col>
          <RevenueAndFeed />
        </Row>
      </VoucherContextProvider>
    </div>
  );
}
