import React from 'react';
import ReactDOM from 'react-dom';
import indexRoutes from './routes/index.jsx';
import { HashRouter, Route, Switch } from 'react-router-dom'

import './assets/scss/style.css';
import AuthContextProvider from './context/authProvider.js';



ReactDOM.render(
  <HashRouter>
    <AuthContextProvider>
      <Switch>
        {indexRoutes.map((prop, key) => {
          return <Route path={prop.path} key={key} component={prop.component} />;
        })}
      </Switch>
    </AuthContextProvider>
  </HashRouter>
  
  ,document.getElementById('root')); 
