import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link, Route } from "react-router-dom";
import { Form, Formik } from "formik";
import StoreEdit from "./StoreEdit";
import StoreAdd from "./StoreAdd";
import StoreService from "../../api/services/storeService";
import Loader from "../loader/loader";
import { ToastContext } from "../../context/toastProvider";
import { useHistory } from "react-router-dom";
import GooglePlacesAutoComplete, {
  getLatLng,
  geocodeByAddress,
} from "react-google-places-autocomplete";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function StoreList() {
  const [modal, setModal] = useState(false);
  const [storesList, setStoresList] = useState([{}]);
  const [isLoading, setIsLoading] = useState(true);
  const [delConfirm, setDelConfirm] = useState(false);
  const history = useHistory();
  const [edit, setEdit] = useState(false);
  const { initToast } = useContext(ToastContext);
  const [val, setVal] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [inputVal, setInputVal] = useState("");
  const [categories, setCategories] = useState();
  const [full, setFull] = useState(null);
  const { goBack } = useHistory();
  const [ed, setEd] = useState(false);
  const [addStore, setAddStore] = useState(false);
  const [values, setValues] = useState({
    storeName: "",
    streetAddress1: "",
    streetAddress2: "-",
    suburb: "",
    city: "",
    state: "",
    ZIP: "",
    coordinates: "",
    // storeDescription: "",
    emailAddress: "",
    contactNumber: "",
    categoryID: "",
  });

  useEffect(() => {
    storesInit();
  }, [delConfirm, addStore, initToast]);

  useEffect(() => {
    handleChange();
  }, [val]);

  const handleChange = async () => {
    if (val !== "") {
      const latLng = await geocodeByAddress(val);
      let lat = latLng[0].geometry.location.lat();
      let lng = latLng[0].geometry.location.lng();
      const [suburb, city, postalCode, state, streetAddress] =
        await geocodeByAddress(val).then((results) =>
          getSuburbCity(results[0])
        );
      setValues({
        ...values,
        suburb: suburb,
        city: city,
        state: state,
        ZIP: postalCode,
        streetAddress1: streetAddress,
        coordinates: String(lat) + "," + String(lng),
      });
    }
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      const resp = await StoreService.addStore(values);
      console.log(values);
      if (resp.success) {
        initToast("success", resp.message);
        setIsLoading(false);
        setAddStore(!addStore);
      } else {
        initToast("error", resp.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getSuburbCity = (results) => {
    console.log(results);
    let suburb = "";
    let city = "";
    let postalCode = "";
    let state = "";
    let streetAddress = "";
    results.address_components.forEach((comp) => {
      if (comp.types.includes("sublocality")) suburb = comp.short_name;
      if (comp.types.includes("locality")) city = comp.short_name;
      if (comp.types.includes("postal_code")) postalCode = comp.short_name;
      setIsValid(true);
      if (comp.types.includes("administrative_area_level_1"))
        state = comp.long_name;
    });
    streetAddress = results.formatted_address.split(",")[0];
    return [suburb, city, postalCode, state, streetAddress];
  };

  const storesInit = async () => {
    try {
      const resp = await StoreService.getStores();
      if (resp.code === 0) {
        setStoresList(resp.storeList);
        setIsLoading(false);
      }
      const cats = await StoreService.getCategories();
      if (cats) {
        setCategories(cats.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const deleteStore = async (id) => {
    let store = storesList.find((o) => o.STOREID == id);
    let body = {
      storeName: store.STORENAME,
      streetAddress1: store.STREETADDRESS1,
      streetAddress2: store.STREETADDRESS2,
      suburb: store.SUBURB,
      city: store.CITY,
      state: store.STATE,
      ZIP: store.ZIP,
      coordinates: "11, 11",
      storeDescription: store.STOREDESCRIPTION,
      emailAddress: store.EMAILADDRESS,
      contactNumber: store.CONTACTNUMBER,
      categoryID: store.CATEGORYID,
      storeID: store.STOREID,
      active: "1",
      delete: "1",
    };
    try {
      const resp = await StoreService.updateStore(body);
      setDelConfirm(false);
      if (resp.success) {
        initToast("success", resp.message);
        // goBack();
        history.push("/store-manager");
      }
    } catch (error) {}
  };
  const toggle = () => {
    setModal(true);
  };

  return (
    <>
      <Card>
        <CardTitle className="border-bottom p-3 mb-0 font-weight-bold text-uppercase">
          <i class="fas fa-store-alt"></i> Manage Stores
          {/* <Link
                        to="/store-manager/add-store"
                        key="AddStore"
                        onClick={toggle}
                    >
                        <Button
                            className="btn"
                            color="primary"
                            size="md"
                            style={{ float: "right" }}
                        >
                            <i class="fas fa-plus-circle"></i> Add Store
                        </Button>
                    </Link> */}
          <Button
            className="btn"
            color="primary"
            size="md"
            style={{ float: "right" }}
            onClick={() => setEdit(!edit)}
          >
            <i class="fas fa-plus-circle"></i> Add Store
          </Button>
        </CardTitle>
        <CardBody>
          <p className="text-secondary pl-2">
            {edit
              ? "Enter your store’s address correctly so that customers can easily find you. Click ADD STORE to save changes and updates."
              : "Add, edit or remove individual stores."}
          </p>
          <Loader isLoading={isLoading} />
          <table className="table stylish-table mb-0 mt-2 no-wrap v-middle">
            <thead>
              <tr>
                <th className="font-weight-bold text-muted border-0 border-bottom">
                  Name
                </th>
                <th className="font-weight-bold text-muted border-0 border-bottom">
                  Email Address
                </th>
                <th className="font-weight-bold text-muted border-0 border-bottom">
                  Contact Number
                </th>
                <th
                  className="font-weight-bold text-muted border-0 border-bottom"
                  colSpan="2"
                >
                  Address
                </th>
                <th className="font-weight-bold text-muted border-0 border-bottom">
                  Edit
                </th>
                <th className="font-weight-bold text-muted border-0 border-bottom">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {storesList.map((store) => (
                <tr key={store.id}>
                  <td>
                    {/* <input
                                        disabled={edit ? false : true}
                                        className=" font-weight-medium mb-0 nowrap storeInput"
                                        value={store.STORENAME}
                                        placeholder={''}
                                    /> */}
                    <h6 className="font-weight-medium mb-0 nowrap">
                      {store.STORENAME}
                    </h6>
                  </td>
                  <td>
                    <h6 className="font-weight-medium mb-0 nowrap">
                      {store.EMAILADDRESS}
                    </h6>
                  </td>
                  <td>
                    <h6 className="font-weight-medium mb-0 nowrap">
                      {store.CONTACTNUMBER}
                    </h6>
                  </td>
                  <td>
                    <h6 className="font-weight-medium mb-0 nowrap text-capitalize">
                      {store.STREETADDRESS1}
                    </h6>
                    <small className="text-muted no-wrap text-capitalize">
                      {store.STATE} | {store.CITY} | {store.SUBURB} |{" "}
                      {store.ZIP}
                    </small>
                  </td>
                  <td></td>
                  <td>
                    <Link
                      to={`/store-manager/edit-store/${store.STORENAME}`}
                      onClick={toggle}
                      key="EditStore"
                    >
                      <i style={{ color: "#009efb" }} className="fas fa-edit" />
                    </Link>
                  </td>
                  <td className="center">
                    <button
                      onClick={() => setDelConfirm(true)}
                      style={{
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      <i
                        style={{ color: "#009efb" }}
                        className="fas fa-trash"
                      />
                    </button>
                    <Modal isOpen={delConfirm}>
                      <ModalHeader className="font-weight-bold text-uppercase">
                        Confirm Delete
                      </ModalHeader>
                      <ModalBody>
                        <p>
                          You are about to delete this store. Please note this
                          cannot be undone
                        </p>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          className="btn"
                          color="primary"
                          size="md"
                          style={{ float: "right" }}
                          onClick={() => deleteStore(store.STOREID)}
                        >
                          Confirm
                        </Button>
                        <Button
                          className="btn"
                          color="secondary"
                          size="md"
                          style={{ float: "right" }}
                          onClick={() => setDelConfirm(false)}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </td>
                </tr>
              ))}
              {edit ? (
                <>
                  <tr>
                    <td colspan={2}>
                      <input
                        className="form-control"
                        placeholder="Store Name"
                        onChange={(e) =>
                          setValues({
                            ...values,
                            storeName: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td colspan={2}>
                      <input
                        className="form-control"
                        placeholder="Email Address"
                        onChange={(e) =>
                          setValues({
                            ...values,
                            emailAddress: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td colspan={2}>
                      {/* <input
                                            className="form-control font-small"
                                            placeholder="Contact Number"
                                            onChange={(e) =>
                                                setValues({
                                                    ...values,
                                                    contactNumber:
                                                        e.target.value,
                                                })
                                            }
                                        /> */}
                      <PhoneInput
                        className="form-control"
                        id="mobileNumber"
                        name="mobileNumber"
                        defaultCountry="US"
                        style={{
                          display: "flex",
                        }}
                        placeholder="Mobile Number"
                        onChange={(e) =>
                          setValues({
                            ...values,
                            contactNumber: e,
                          })
                        }
                      />
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td
                      colspan={4}
                      style={{ borderTop: 0 }} //AIzaSyCjHBjPgcKI_w-F1-7TT6i5csVzbN4fj7s
                    >
                      <GooglePlacesAutoComplete
                        apiKey="AIzaSyDgWDAYziwrfcUclkU-1R52RnzlWr0RT0s"
                        className="form-control"
                        selectProps={{
                          styles: {
                            control: (provided) => ({
                              ...provided,
                              border: !isValid
                                ? "1px solid red"
                                : "0.5px solid grey",
                            }),
                          },
                          placeholder: val ? val : "Start typing your address",
                          onInputChange: (e) => {
                            setInputVal(e);
                          },
                          onChange: (e) => {
                            setFull(e);
                            setVal(e.value.description);
                          },
                          onMenuClose: () => {
                            setInputVal(val);
                          },
                          onFocus: (e) => {
                            setVal(inputVal);
                            setInputVal("");
                            setFull(null);
                          },
                          inputValue: inputVal,
                          value: "",
                          blurInputOnSelect: true,
                          // styles: {
                          //     input: (provided) => ({
                          //         ...provided,
                          //         minWidth: 300,
                          //     }),
                          // },
                        }}
                      />
                      {!isValid ? (
                        <p
                          style={{
                            position: "absolute",
                            bottom: -10,
                            margin: 0,
                            fontSize: 12,
                            color: "red",
                          }}
                        >
                          Please select an address with a zip code
                        </p>
                      ) : null}
                    </td>
                    <td colspan={2} style={{ borderTop: 0 }}>
                      <select
                        className="form-control"
                        placeholder="Categories"
                        onChange={(e) =>
                          setValues({
                            ...values,
                            categoryID: e.target.value,
                          })
                        }
                      >
                        <option selected>Please select a category</option>
                        {categories
                          ? categories.map((x) => (
                              <option value={x.CATEGORYID}>
                                {x.CATEGORYNAME}
                              </option>
                            ))
                          : null}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td colspan={2}>
                      <input
                        className="form-control"
                        placeholder="Street Address"
                        value={values.streetAddress1}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            streetAddress1: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td colspan={1}>
                      <input
                        className="form-control"
                        placeholder="Suburb"
                        value={values.suburb}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            suburb: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td colspan={1}>
                      <input
                        className="form-control"
                        placeholder="Town/City"
                        value={values.city}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            city: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td colspan={1}>
                      <input
                        className="form-control"
                        placeholder="State"
                        value={values.state}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            state: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td colspan={1}>
                      <input
                        className="form-control"
                        placeholder="ZIP / Postal Code"
                        value={values.ZIP}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            ZIP: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan={1}>
                      <Button
                        className="btn"
                        disabled={!isValid}
                        color={isValid ? "primary" : "secondary"}
                        size="md"
                        style={{ width: "100%" }}
                        onClick={() => onSubmit(values)}
                      >
                        <i class="fas fa-plus-circle"></i> Done
                      </Button>
                    </td>
                  </tr>
                </>
              ) : null}
            </tbody>
          </table>
        </CardBody>
      </Card>
      <Route
        path="/store-manager/add-store"
        key="AddStore"
        render={(props) => (
          <StoreAdd {...props} toggle={toggle} modal={modal} />
        )}
      />
      <Route
        path="/store-manager/edit-store/:name"
        key="EditStore"
        render={(props) => (
          <StoreEdit
            {...props}
            toggle={toggle}
            storeList={storesList}
            modal={modal}
          />
        )}
      />
    </>
  );
}

export default StoreList;
