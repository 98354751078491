import React, { useContext, useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import VoucherService from "../../../api/services/voucherService";
import { VoucherContext } from "../../../context/ValidateVoucherContext";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from "reactstrap";
import { ToastContext } from "../../../context/toastProvider";
import StoreService from "../../../api/services/storeService";
import Loader from "../../loader/loader";

export default withRouter(function RedeemVoucher({ match }, props) {
  const { voucher, getData } = useContext(VoucherContext);
  const { initToast } = useContext(ToastContext);
  const [confirm, setConfirm] = useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [stores, setStores] = useState();
  const [redeemVoucher, setRedeemVoucher] = useState({
    voucherCode: voucher.voucherCode,
    reference: "",
    amount: voucher.amount,
    mobileNumber: "",
    storeID: "29",
  });

  useEffect(() => {
    onPageInit();
    if (voucher.voucherCode === "") {
      history.goBack();
    }
  }, []);

  const onPageInit = async () => {
    try {
      const resp = await StoreService.getStores();
      if (resp.code === 0) {
        let temp = [];
        resp.storeList.forEach((item, key) => {
          if (item.CATEGORYID) {
            temp.push({
              name: item.STORENAME + " (" + item.CATEGORYNAME + ")",
              id: item.STOREID,
            });
          }
        });
        setStores(temp);
      }
    } catch (error) {}
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const resp = await VoucherService.voucherRedeem(redeemVoucher);
      console.log(redeemVoucher);
      if (resp.success) {
        setIsLoading(false);
        initToast("success", resp.message);
        setConfirm(false);
        history.push("/process-voucher");
        getData();
      }
      console.log(resp);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className=" font-weight-bolder">
      <Modal isOpen={confirm}>
        <Loader isLoading={isLoading} />
        <ModalHeader className="font-weight-bold text-uppercase">
          Please confirm your redemption
        </ModalHeader>
        <ModalBody>
          <p>
            You are about to Redeem ${redeemVoucher.amount / 100} on this
            voucher. Please note this cannot be undone
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn"
            color="primary"
            size="md"
            style={{ float: "right" }}
            onClick={() => handleSubmit()}
          >
            Confirm
          </Button>
          <Button
            className="btn"
            color="secondary"
            size="md"
            style={{ float: "right" }}
            onClick={() => setConfirm(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <div>
        <table className="table stylish-table mb-0 mt-2 no-wrap v-middle border-bottom">
          <thead>
            <tr>
              <th className="font-weight-bold text-muted border-0 border-bottom">
                Voucher Code
              </th>
              <th className="font-weight-bold text-muted border-0 border-bottom">
                Voucher Value
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h6 className="font-weight-medium mb-0 nowrap">
                  {voucher.voucherCode}
                </h6>
              </td>
              <td>
                <h6 className="font-weight-medium mb-0 nowrap">
                  ${(voucher.amount / 100).toFixed(2)}
                </h6>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div
          className="row g-1"
          style={{
            maxWidth: "600px",
            width: "100%",
          }}
        >
          <div className="col-12">
            <label for="storeID"></label>
            <select
              className="form-control"
              id="storeID"
              placeholder="Store Name"
              onChange={(e) =>
                setRedeemVoucher({
                  ...redeemVoucher,
                  storeID: e.target.value,
                })
              }
            >
              <option value={0} disabled selected>
                Select a Store...
              </option>
              {stores?.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </div>
          <div className="col-12" style={{ marginTop: "1.6em" }}>
            <button
              onClick={() => setConfirm(true)}
              className="btn btn-primary"
            >
              Redeem
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

// [
//     {
//         "DATEADDED": "2024-01-30 05:50:55",
//         "AMOUNT": 1200
//     },
//     {
//         "DATEADDED": "2024-01-30 05:50:55",
//         "AMOUNT": 1500
//     },
//     {
//         "DATEADDED": "2024-03-30 05:50:55",
//         "AMOUNT": 2500
//     },
//     {
//         "DATEADDED": "2024-04-30 05:50:55",
//         "AMOUNT": 200
//     },
//     {
//         "DATEADDED": "2024-05-30 05:50:55",
//         "AMOUNT": 2200
//     }
// ]
