import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
} from "reactstrap";
import { Link } from "react-router-dom";
import Loader from "../loader/loader";
import staffService from "../../api/services/staffService";
import { ToastContext } from "../../context/toastProvider";
import profilephoto from "../../assets/images/background/navImg.png";

function UserList() {
  const [staffList, setStaffList] = useState([{}]);
  const [isLoading, setIsLoading] = useState();
  const [delUserConfirm, setDelUserConfirm] = useState(-1);
  const { initToast } = useContext(ToastContext);

  useEffect(() => {
    onPageInit();
  }, [setStaffList]);

  const onPageInit = async () => {
    setIsLoading(true);
    try {
      const resp = await staffService.getAllStaff();
      if (resp.success == 0) {
        setStaffList(resp.staffInfo);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <Loader isLoading={isLoading} />
      <CardTitle className="border-bottom p-3 mb-0 font-weight-bold text-uppercase">
        <i class="fas fa-users-cog"></i>
        Staff Manager
        <Link to="/user-manager/add-user">
          <Button
            className="btn"
            color="primary"
            size="md"
            style={{ float: "right" }}
          >
            <i className="fas fa-user-plus"></i> Add User
          </Button>
        </Link>
      </CardTitle>
      <CardBody>
        <p className="text-secondary">
          Set up and manage administrative control of your STND APRT account
          here. You can edit roles and add staff as required.
        </p>
        <table className="table stylish-table mb-0 mt-2 no-wrap v-middle">
          <thead>
            <tr>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                Name
              </th>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                Email
              </th>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                Mobile Number
              </th>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                Role
              </th>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                Status
              </th>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                Edit
              </th>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {staffList.map((user, index) => (
              <tr
                className={"font-weight-bolder"}
                style={user.ACTIVE == 1 ? {} : { color: "darkgrey" }}
              >
                <td style={{ display: "flex", alignItems: "center" }}>
                  {localStorage.getItem("id") ===
                  JSON.stringify(user.STAFFID) ? (
                    <img className="userImg" src={profilephoto} />
                  ) : null}
                  <h6 className="font-weight-medium mb-0 nowrap">
                    {user.FIRSTNAME} {user.LASTNAME}
                  </h6>
                </td>
                <td>{user.EMAILADDRESS}</td>
                <td>{user.MOBILENUMBER}</td>
                <td>{user.ROLEID === 1 ? "Admin" : "Staff"}</td>
                <td>
                  <span
                    className={
                      user.ACTIVE === 1
                        ? "badge badge-light-success text-success"
                        : "badge badge-light-danger text-danger"
                    }
                  >
                    {user.ACTIVE === 1 ? "Active" : "Disabled"}
                  </span>
                </td>
                <td>
                  <Link to={`/user-manager/edit-user/${user.STAFFUID}`}>
                    <i className="fas fa-edit"></i>
                  </Link>
                </td>
                <td>
                  <i
                    onClick={async () => {
                      user.ROLEID !== 1 && setDelUserConfirm(index);
                    }}
                    className="fas fa-trash"
                    style={{
                      color: user.ROLEID === 1 ? "#808080" : "#009efb",
                      marginLeft: 16,
                      cursor: user.ROLEID === 1 ? "not-allowed" : "pointer",
                    }}
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal isOpen={delUserConfirm === -1 ? false : true}>
          <ModalHeader className="font-weight-bold text-uppercase">
            Confirm Delete
          </ModalHeader>
          <ModalBody>
            <p>
              You are about to delete this Staff. Please note this cannot be
              undone
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn"
              color="primary"
              size="md"
              style={{ float: "right" }}
              onClick={async () => {
                try {
                  const resp = await staffService.deleteStaff(
                    staffList[delUserConfirm].STAFFUID
                  );
                  if (resp.success) {
                    setStaffList(
                      staffList.length > 1
                        ? staffList.filter(
                            (_, subIndex) => subIndex !== delUserConfirm
                          )
                        : []
                    );
                    setDelUserConfirm(-1);
                  }
                } catch (error) {}
              }}
            >
              Confirm
            </Button>
            <Button
              className="btn"
              color="secondary"
              size="md"
              style={{ float: "right" }}
              onClick={() => setDelUserConfirm(-1)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </CardBody>
    </Card>
  );
}

export default UserList;
