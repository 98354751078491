import React from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import InfoIcon from "../../../components/icons/InfoIcon";

const Sidebar = (props) => {
  const expandLogo = () => {
    document.getElementById("logobg").classList.toggle("expand-logo");
  };

  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "selected" : "";
  };
  // isInitialPopup
  // setShowInitialPopup
  return (
    <aside
      className="left-sidebar"
      id="sidebarbg"
      data-sidebarbg="skin6"
      onMouseEnter={expandLogo.bind(null)}
      onMouseLeave={expandLogo.bind(null)}
    >
      <div className="scroll-sidebar">
        <PerfectScrollbar className="sidebar-nav">
          <Nav id="sidebarnav">
            {props.routes
              .filter((item) => item.subMenu !== true)
              .map((prop, key) => {
                if (prop.redirect) {
                  return null;
                } else {
                  return (
                    <li
                      className={
                        activeRoute(prop.path) +
                        (prop.pro ? " active active-pro" : "") +
                        " sidebar-item"
                      }
                      key={key}
                    >
                      <NavLink
                        to={prop.path}
                        className="sidebar-link"
                        activeClassName="active"
                      >
                        <i className={prop.icon} />
                        <span className="hide-menu">{prop.name}</span>
                      </NavLink>
                    </li>
                  );
                }
              })}
            <li className=" sidebar-item" data-tour="reactour__1">
              <p
                className="sidebar-link"
                activeClassName="active"
                style={{ marginBottom: 0 }}
                onClick={() => props.setSubMenu(!props.subMenu)}
              >
                <i
                  className={"fas fa-chevron-down"}
                  style={
                    props.subMenu
                      ? { transform: "rotate(-180deg)", transition: "0.7s" }
                      : { transition: "0.7s" }
                  }
                />
                <span className="hide-menu">Manage Account</span>
                <div
                  style={{ marginLeft: 16 }}
                  onMouseOver={() => props.setShowInitialPopup(true)}
                  onMouseOut={() => props.setShowInitialPopup(false)}
                >
                  <InfoIcon width={25} height={25} color={"#808080"} />
                </div>
              </p>
            </li>
            <div
              style={
                props.subMenu
                  ? { height: 300, transition: "0.7s" }
                  : { height: 0, transition: "0.7s", overflow: "hidden" }
              }
            >
              <li className=" sidebar-item" data-tour="reactour__2">
                <NavLink
                  to={"/profile-manager"}
                  className="sidebar-link"
                  style={{ padding: "10px 35px 10px 8px" }}
                  activeClassName="active"
                >
                  <i className={""} />
                  <span className="hide-menu">Manage Profile</span>
                  {props.isInitialPopup && (
                    <img
                      src={require("../../../assets/images/intro1.png")}
                      style={{
                        width: 212,
                        position: "fixed",
                        left: 163,
                        top: 262,
                      }}
                    />
                  )}
                </NavLink>
              </li>
              <li className=" sidebar-item" data-tour="reactour__4">
                <NavLink
                  to={"/payment-details"}
                  className="sidebar-link"
                  style={{ padding: "10px 35px 10px 8px" }}
                  activeClassName="active"
                >
                  <i className={""} />
                  <span className=" hide-menu">Payout Details</span>
                </NavLink>
              </li>
              <li className=" sidebar-item"></li>
              <li className=" sidebar-item" data-tour="reactour__5">
                <NavLink
                  to={"/user-manager"}
                  className="sidebar-link"
                  style={{ padding: "10px 35px 10px 8px" }}
                >
                  <i className={""} />
                  <span className="hide-menu">Staff Manager</span>
                </NavLink>
              </li>
              <li className=" sidebar-item" data-tour="reactour__6">
                <NavLink
                  to={"/generate-qrcode"}
                  className="sidebar-link"
                  style={{ padding: "10px 35px 10px 8px" }}
                >
                  <i className={""} />
                  <span className="hide-menu">Marketing Tools</span>
                </NavLink>
              </li>
            </div>
          </Nav>
        </PerfectScrollbar>
      </div>
    </aside>
  );
};
export default Sidebar;
