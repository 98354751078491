import * as api from '..';
import { VOUCHERS } from '../config/endpoints';

interface VoucherService {
    voucherStatus: (body: {}) => Promise<any>;
    voucherRedeem: (body: {}) => Promise<any>;
    voucherHistory: (body: {}) => Promise<any>;
}

const VoucherService: VoucherService = {
    voucherStatus: async (body) => {
        const resp = await api.post(VOUCHERS.voucherStatus, body);
        return resp;
    },
    voucherRedeem: async (body) => {
        const resp = await api.post(VOUCHERS.voucherRedeem, body);
        return resp;
    },
    voucherHistory: async (body) => {
        const resp = await api.post(VOUCHERS.voucherHistory, body);
        return resp;
    },
};

export default VoucherService;
