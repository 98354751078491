import React, { useState, useContext, useEffect, useRef } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import { ToastContext } from "../../context/toastProvider";
import StoreService from "../../api/services/storeService";
import Loader from "../loader/loader";
import campaignService from "../../api/services/campaignService";
import Dropzone from "react-dropzone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import InfoIcon from "../icons/InfoIcon";
import "./Campaign.css";
import DownloadIcon from "../icons/DownloadIcon";
import CampaignPDF from "../../assets/Create_Campaign.pdf";
import CampaignPdfThumbnail from "../../assets/images/campaignPdfThumbnail.png";
import SearchIcon from "../icons/searchIcon";
import { Fancybox } from "@fancyapps/ui";

function AddCampaign() {
  const [isInitialPopup, setShowInitialPopup] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const { initToast } = useContext(ToastContext);
  const [stores, setStores] = useState();
  const [banner, setBanner] = useState();
  const [fileBanner, setFileBanner] = useState();
  const history = useHistory();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [b64, setB64] = useState();

  const getStores = async () => {
    try {
      const resp = await StoreService.getStores();
      if (resp.code === 0) {
        let temp = [];
        resp.storeList.forEach((item, key) => {
          temp.push({
            name: item.STORENAME,
            id: item.STOREID,
          });
        });
        setStores(temp);
      }
    } catch (error) {}
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileReadBanner = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    setB64(base64.replace(/^data:image\/[a-z]+;base64,/, ""));
  };

  const handleOnClick = () => {
    setShowInitialPopup(false);
    document.body.removeEventListener("click", () => {});
  };
  useEffect(() => {
    const item = localStorage.getItem("initialPopupCampaionManage");
    if (item && item === "1") {
      setShowInitialPopup(true);
      localStorage.setItem("initialPopupCampaionManage", "");
      document.body.addEventListener("click", handleOnClick);
    }

    Fancybox.bind('[data-fancybox="gallery"]', {
      // Настройки Fancybox
    });

    getStores();
    return () => {
      Fancybox.destroy();
    };
  }, []);
  const [editImage, setEditImage] = useState(false);
  const cropperRef = useRef(null);
  const onSaveCrop = async (e) => {
    e.preventDefault();
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setBanner({
      ...banner,
      campaignBanner: cropper.getCroppedCanvas().toDataURL(),
    });
    const base64 = cropper.getCroppedCanvas().toDataURL();
    setB64(base64.replace(/^data:image\/[a-z]+;base64,/, ""));
    setFileBanner(cropper.getCroppedCanvas().toDataURL());

    setEditImage(false);
  };

  const onClearCrop = (e) => {
    e.preventDefault();
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (cropper) {
      cropper.clear();
    }
    setFileBanner(null);
    setBanner({
      ...banner,
      campaignBanner: null,
    });
    setEditImage(false);
  };
  return (
    <Card>
      <CardTitle className="border-bottom p-3 mb-0 font-weight-bold text-uppercase">
        Create Campaign
      </CardTitle>
      <CardBody>
        <p className="text-secondary">
          Set up your campaign by completing all of the fields provided, as
          instructed. Then click on CREATE CAMPAIGN to save your campaign for
          sending to your database.
        </p>
        <Loader isLoading={isLoading} />
        <Formik
          initialValues={{
            campaignName: "",
            give_back_campaign: "",
            campaignDescription: "",
            minimumValue: "",
            startDate: "",
            endDate: "",
            campaignIntro: "",
            dashboardMemo: "-",
            campaignSMSText: "",
          }}
          enableReinitialize
          onSubmit={async (values) => {
            if (values.give_back_campaign < 15) {
              initToast(
                "error",
                "To build loyalty the minimum Give-back Percentage should be 15% or higher"
              );
            } else {
              setIsLoading(true);
              try {
                const resp = await campaignService.createCampaign({
                  ...values,
                  campaignBanner: b64,
                  startDate: startDate,
                  endDate: endDate,
                  active: "1",
                });
                if (resp && resp.success) {
                  setIsLoading(false);
                  history.goBack();
                  initToast("success", resp.message);
                } else {
                  setIsLoading(false);
                  initToast("error", resp.message);
                }
              } catch (error) {
                setIsLoading(false);
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className="row g-2" onSubmit={handleSubmit}>
              <div className="col-6">
                <div className={"info-input"}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Title of offer for listing card"
                    id="campaignName"
                    value={values.campaignName}
                    onChange={handleChange}
                    name="campaignName"
                  />
                  <div className="info-input__icon">
                    <InfoIcon />
                  </div>
                  <div className="info-input__img">
                    <img src="/images/infoCampaign/1.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-3">
                <DatePicker
                  onChange={(date) => setStartDate(date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Start Date"
                  selected={startDate}
                  className="form-control"
                />
              </div>
              <div className="col-3 ">
                <DatePicker
                  onChange={(date) => setEndDate(date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="End Date"
                  selected={endDate}
                  className="form-control"
                />
              </div>
              <div className="col-6 mt-3">
                <div className={"info-input"}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Offer headline for 'Buy' card"
                    id="campaignIntro"
                    value={values.campaignIntro}
                    onChange={handleChange}
                    name="campaignIntro"
                  />
                  <div className="info-input__icon">
                    <InfoIcon />
                  </div>
                  <div className="info-input__img">
                    <img src="/images/infoCampaign/2.jpg" alt="" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className={"info-input"}>
                      <input
                        className="form-control mt-3"
                        placeholder="Numeric purchase amount"
                        id="minimumValue"
                        type="number"
                        value={values.minimumValue}
                        onChange={handleChange}
                        name="minimumValue"
                      />
                      <div className="info-input__icon">$</div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={"info-input"}>
                      <input
                        className="form-control mt-3"
                        placeholder="Give-Back percentage (15%+)"
                        id="give_back_campaign"
                        type="number"
                        value={values.give_back_campaign}
                        onChange={handleChange}
                        name="give_back_campaign"
                      />
                      <div className="info-input__icon" style={{ right: 43 }}>
                        %
                      </div>
                      <div
                        className="info-input__icon"
                        onMouseOver={() => setShowInitialPopup(true)}
                        onMouseOut={() => setShowInitialPopup(false)}
                      >
                        <InfoIcon />
                      </div>
                      <div
                        className="info-input__img"
                        style={{
                          top: -17,
                          opacity: isInitialPopup ? 1 : 0,
                          visibility: isInitialPopup ? "visible" : "hidden",
                        }}
                      >
                        <img
                          src={require("../../assets/images/intro3.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={"info-input"}>
                  <textarea
                    maxLength="250"
                    className="form-control mt-3"
                    placeholder="Offer Description"
                    id="campaignDescription"
                    style={{ minHeight: "150px" }}
                    type="textarea"
                    name="campaignDescription"
                    value={values.campaignDescription}
                    onChange={handleChange}
                  />
                  <p>{values.campaignDescription.length}/250</p>
                  <div className="info-input__icon">
                    <InfoIcon />
                  </div>
                  <div className="info-input__img">
                    <img src="/images/infoCampaign/3.jpg" alt="" />
                  </div>
                </div>

                <>
                  <textarea
                    className="form-control mt-3"
                    placeholder="Campaigns SMS message: (be sure to include your business name so your customers know it’s from you)"
                    id="campaignSMSText"
                    maxLength="120"
                    style={{ minHeight: "100px" }}
                    type="textarea"
                    name="campaignSMSText"
                    value={values.campaignSMSText}
                    onChange={handleChange}
                  />
                  <p>{values.campaignSMSText.length}/120</p>
                </>
              </div>

              <div className="col-6 mt-3">
                <div className="col-12 p-1">
                  <label htmlFor="banner">
                    Banner (Your banner will be resized to 600px x 300px)
                  </label>
                  {editImage ? (
                    <>
                      <Cropper
                        src={banner?.campaignBanner}
                        style={{ height: 400, width: 600 }}
                        aspectRatio={6 / 3}
                        guides={false}
                        ref={cropperRef}
                      />
                      <div style={{ dispay: "flex", margin: "30px 0" }}>
                        <button
                          onClick={(e) => onClearCrop(e)}
                          className="btn btn-primary mt-3 mr-2 "
                          style={{ float: "left" }}
                        >
                          Clear
                        </button>
                        <button
                          onClick={(e) => onSaveCrop(e)}
                          className="btn btn-primary mt-3 "
                          style={{ float: "left" }}
                        >
                          Save Image
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="mb-0 col-12 p-0">
                      <img
                        src={banner ? banner.campaignBanner : null}
                        style={{
                          width: "100%",
                          minHeight: 150,
                          borderRadius: "10px",
                        }}
                      />
                      <section className="container overlayControl">
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            setFileBanner(
                              acceptedFiles.map((file) =>
                                Object.assign(file, {
                                  preview: URL.createObjectURL(file),
                                })
                              )
                            );
                            setBanner({
                              ...banner,
                              campaignBanner: acceptedFiles[0].preview,
                            });
                            setFileBanner(acceptedFiles[0].preview);
                            setEditImage(true);
                          }}
                          name="logoFile"
                          multiple={false}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps({
                                className: "dropzone overlayControl",
                              })}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <input
                                id="banner"
                                {...getInputProps()}
                                onChange={(e) => {
                                  handleFileReadBanner(e);
                                  console.log(e.target.files);

                                  setBanner({
                                    ...banner,
                                    campaignBanner: URL.createObjectURL(
                                      e.target.files[0]
                                    ),
                                  });
                                  setEditImage(true);
                                }}
                              />
                              <div className="perfectCenter">
                                <i className="fas fa-upload" />
                                <p className="">
                                  Drag and Drop your image or click to upload
                                </p>
                                <p className="">
                                  Using copyrighted images without permission is
                                  illegal, so ensure you use original, or
                                  royalty-free images for your campaigns.
                                </p>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </section>
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn btn-primary mt-3 "
                  style={{ float: "right" }}
                >
                  Create Campaign
                </button>
                <Link
                  to={"/campaign-manager"}
                  className="btn btn-secondary mt-3 mr-2"
                  style={{ float: "right" }}
                >
                  Cancel
                </Link>
                <div className="campaign-pdf-container mt-5">
                  <img src={CampaignPdfThumbnail} alt="Combined with QR Code" />
                  <div className="campaign-pdf-footer">
                    <span className="campaign-pdf-text">
                      {"How to Create a Campaign:\nA guide to what goes where"}
                    </span>
                    <a data-fancybox="gallery" href={CampaignPdfThumbnail}>
                      <SearchIcon />
                    </a>
                    <a download href={CampaignPDF}>
                      <DownloadIcon />
                    </a>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
}

export default AddCampaign;
