import * as api from "..";
import { CAMPAIGNS } from "../config/endpoints";

interface CampaignService {
  getCampaigns: (id: string) => Promise<any>;
  getCampaignGroups: (id: string) => Promise<any>;
  createCampaign: (body: {}) => Promise<any>;
  updateCampaign: (body: {}) => Promise<any>;
  addMember: (body: {}) => Promise<any>;
  getCredits: () => Promise<any>;
  sendCampaign: (body: {}) => Promise<any>;
  updateMember: (body: {}) => Promise<any>;
  deleteMember: (subscriberID: string) => Promise<any>;
  uploadCsv: (body: {}) => Promise<any>;
  updateStatus: (body: { campaignID: string; active: number }) => Promise<any>;
}

const campaignService: CampaignService = {
  getCampaigns: async (id: string) => {
    const resp = await api.get(CAMPAIGNS.getCampaigns + id);
    return resp;
  },
  createCampaign: async (body: {}) => {
    const resp = await api.post(CAMPAIGNS.createCampaign, body);
    return resp;
  },
  updateCampaign: async (body: {}) => {
    const resp = await api.post(CAMPAIGNS.updateCampaign, body);
    return resp;
  },
  getCampaignGroups: async (id: string) => {
    const resp = await api.get(CAMPAIGNS.getCampaignGroups + id);
    return resp;
  },
  addMember: async (body: {}) => {
    const resp = await api.post(CAMPAIGNS.addMember, body);
    return resp;
  },
  updateMember: async (body: {}) => {
    const resp = await api.post(CAMPAIGNS.updateMember, body);
    return resp;
  },
  getCredits: async () => {
    const resp = await api.get(CAMPAIGNS.getCredits);
    return resp;
  },
  sendCampaign: async (body: {}) => {
    const resp = await api.post(CAMPAIGNS.sendCampaign, body);
    return resp;
  },
  uploadCsv: async (body: {}) => {
    const resp = await api.post(CAMPAIGNS.csvUpload, body);
    return resp;
  },
  updateStatus: async (body: {}) => {
    const resp = await api.post(CAMPAIGNS.updateStatus, body);
    return resp;
  },
  deleteMember: async (subscriberID: string) => {
    const resp = await api.deleteCall(
      `${CAMPAIGNS.deleteMember}${subscriberID}`
    );
    return resp;
  },
};

export default campaignService;
