import React, { createContext, useState } from 'react';
import { toast } from 'react-toastify';
import successIcon from '../assets/images/successIcon.png'

export const ToastContext = createContext();

function ToastContextProvider({ children }) {

        const initToast = (type, message) => {

            switch(type){
                case 'success' :
                    toast.success(message, {
                        icon: () => <img src={successIcon} style={{height: 30, width: 20, objectFit: 'contain'}}/>,
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                break;

                case 'error' : 
                    toast.error(message, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                break;
                case 'alert' : 
                    toast.info(message, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                break;
            }
        }



        return(
            <ToastContext.Provider value={{initToast}}>
                {children}
            </ToastContext.Provider>
        )
}

export default ToastContextProvider;