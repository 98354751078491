import React, { useState, useEffect, useContext, useRef } from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import { Link, Route } from "react-router-dom";
import ProfileEdit from "./ProfileEdit";

import partnerService from "../../api/services/partnerService";
import Loader from "../loader/loader";
import Dropzone, { useDropzone } from "react-dropzone";
import { ToastContext } from "../../context/toastProvider";
import Cropper from "react-cropper";
import GooglePlacesAutoComplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import "./Profile.css";
import PhoneInput from "react-phone-number-input";
import StoreService from "../../api/services/storeService";
import InfoIcon from "../icons/InfoIcon";

function Profile() {
  const [isInitialPopup, setShowInitialPopup] = useState(false);
  const [modal, setModal] = useState(false);
  const [toggleRefresh, setToggleRefresh] = useState(false);
  const { getRootProps, getInputProps } = useDropzone();
  const [fileLogo, setFileLogo] = useState([]);
  const [fileBanner, setFileBanner] = useState([]);
  const [edit, toggleEdit] = useState(false);
  const { initToast } = useContext(ToastContext);
  const [partnerImages, setPartnerImages] = useState({
    logoFile: "",
    bannerFile: "",
  });
  const [partner, setPartner] = useState({
    storeID: null,
    streetAddress1: "",
    emailAddress: "",
    contactNumber: "",
    categoryID: [],

    partnerName: "",
    banner: "",
    logo: "",
    givebackPercent: "",
    dateAdded: "",
    partnerDescription: "",
    active: "1",
  });
  const [tempPartner, setTempPartner] = useState(partner);
  const [isLoading, setIsLoading] = useState(false);
  const [archiveStore, setArchiveStore] = useState([]);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileReadLogo = async (event, drag) => {
    if (drag) var file = event;
    else var file = event.target.files[0];
    const base64 = await convertBase64(file);
    setPartnerImages({
      ...partnerImages,
      logoFile: base64.replace(/^data:image\/[a-z]+;base64,/, ""),
    });
  };

  const handleFileReadBanner = async (event, drag) => {
    if (drag) var file = event;
    else var file = event.target.files[0];
    const base64 = await convertBase64(file);
    setPartnerImages({
      ...partnerImages,
      bannerFile: base64.replace(/^data:image\/[a-z]+;base64,/, ""),
    });
  };

  const initEdit = async () => {
    setShowInitialPopup(false);
    switch (edit) {
      case true:
        setIsLoading(true);
        const storesCategoriesListIds = archiveStore.map((store) => {
          return {
            categoryID: store.CATEGORYID,
            storeID: store.STOREID,
          };
        }); // total list categories 1 9.1
        const categoriesDelete = storesCategoriesListIds.filter(
          (item) => !tempPartner.categoryID.includes(item.categoryID)
        ); // tempPartner = 1 9
        const categoriesUpdate = storesCategoriesListIds.filter((item) =>
          tempPartner.categoryID.includes(item.categoryID)
        ); // tempPartner = 1 9

        const categoriesIds = storesCategoriesListIds.map(
          (item) => item.categoryID
        );
        const categoriesNew = tempPartner.categoryID.filter(
          (item) => !categoriesIds.includes(item)
        );

        //delete store if disabled category
        if (categoriesDelete.length) {
          for (const item of categoriesDelete) {
            await StoreService.updateStore({
              storeID: item.storeID,
              storeName: tempPartner.partnerName,
              suburb: tempPartner.suburb,
              city: tempPartner.city,
              state: tempPartner.state,
              ZIP: tempPartner.ZIP,
              coordinates: tempPartner.coordinates,
              streetAddress1: tempPartner.streetAddress1,
              emailAddress: tempPartner.emailAddress,
              contactNumber: tempPartner.contactNumber,
              categoryID: item.categoryID,
              active: tempPartner.active,
              delete: true,
            });
          }
        }

        //update store if stores already exist
        if (categoriesUpdate.length) {
          for (const item of categoriesUpdate) {
            await StoreService.updateStore({
              storeID: item.storeID,
              storeName: tempPartner.partnerName,
              suburb: tempPartner.suburb,
              city: tempPartner.city,
              state: tempPartner.state,
              ZIP: tempPartner.ZIP,
              coordinates: tempPartner.coordinates,
              streetAddress1: tempPartner.streetAddress1,
              emailAddress: tempPartner.emailAddress,
              contactNumber: tempPartner.contactNumber,
              categoryID: item.categoryID,
              active: tempPartner.active,
              delete: false,
            });
          }
        }

        //add store if store doesnt exist
        if (categoriesNew.length) {
          for (const item of categoriesNew) {
            await StoreService.addStore({
              storeName: tempPartner.partnerName,
              suburb: tempPartner.suburb,
              city: tempPartner.city,
              state: tempPartner.state,
              ZIP: tempPartner.ZIP,
              coordinates: tempPartner.coordinates,
              streetAddress1: tempPartner.streetAddress1,
              emailAddress: tempPartner.emailAddress,
              contactNumber: tempPartner.contactNumber,
              categoryID: item,
              active: tempPartner.active,
              delete: false,
            });
          }
        }

        if (tempPartner.storeID) {
        } else {
          await StoreService.addStore({
            storeName: tempPartner.partnerName,
            suburb: tempPartner.suburb,
            city: tempPartner.city,
            state: tempPartner.state,
            ZIP: tempPartner.ZIP,
            coordinates: tempPartner.coordinates,
            streetAddress1: tempPartner.streetAddress1,
            emailAddress: tempPartner.emailAddress,
            contactNumber: tempPartner.contactNumber,
            categoryID: tempPartner.categoryID.join(","),
            active: tempPartner.active,
            delete: false,
          });
        }

        const resp = await partnerService.updatePartner({
          partnerName: tempPartner.partnerName,
          banner: tempPartner.banner,
          dateAdded: tempPartner.dateAdded,
          logo: tempPartner.logo,
          partnerDescription: tempPartner.partnerDescription,
          givebackPercent: tempPartner.givebackPercent,
          active: tempPartner.active,

          logoFile: partnerImages.logoFile,
          bannerFile: partnerImages.bannerFile,
        });
        if (resp) {
          setIsLoading(false);
          initToast("success", resp.message);
          toggleEdit(!edit);
        }
        break;
      case false:
        toggleEdit(true);
        break;
    }
  };

  const cancelEdit = () => {
    setIsLoading(true);
    setTempPartner(partner);
    toggleEdit(!edit);
    setToggleRefresh(!toggleRefresh);
    setFileBanner(null);
    setTempBanner(null);
    setPartnerImages(null);
  };

  const toggle = () => {
    if (modal) {
      setToggleRefresh(!toggleRefresh);
    }
    setModal(!modal);
  };

  useEffect(() => {
    setIsLoading(true);
    onPageInit();
  }, [toggleRefresh]);

  const onPageInit = async () => {
    try {
      const resp = await partnerService.getPartner();
      const resp1 = await StoreService.getStores();

      const currentCategoriesStores = resp1.storeList.length
        ? resp1.storeList.map((item) => item.CATEGORYID)
        : [];

      const currentStore = resp1.storeList.length
        ? resp1.storeList[resp1.storeList.length - 1]
        : {};

      setArchiveStore(resp1.storeList.length ? [...resp1.storeList] : []);
      if (resp.success) {
        setPartner({
          storeID: currentStore.STOREID,
          streetAddress1: currentStore.STREETADDRESS1,
          emailAddress: currentStore.EMAILADDRESS,
          contactNumber: currentStore.CONTACTNUMBER,
          categoryID: currentCategoriesStores,
          suburb: currentStore.SUBURB,
          city: currentStore.CITY,
          state: currentStore.STATE,
          ZIP: currentStore.ZIP,
          coordinates:
            String(currentStore.LATITUDE) +
            "," +
            String(currentStore.LONGITUDE),

          partnerName: resp.data[0].PARTNERNAME,
          banner: resp.data[0].BANNERFILE,
          dateAdded: resp.data[0].DATEADDED,
          logo: resp.data[0].LOGOFILE,
          partnerDescription: resp.data[0].PARTNERDESCRIPTION,
          givebackPercent: resp.data[0].GIVEBACKPERCENT,
          active: resp.data[0].ACTIVE,
        });
        setTempPartner({
          storeID: currentStore.STOREID,
          streetAddress1: currentStore.STREETADDRESS1,
          emailAddress: currentStore.EMAILADDRESS,
          contactNumber: currentStore.CONTACTNUMBER,
          categoryID: currentCategoriesStores,

          suburb: currentStore.SUBURB,
          city: currentStore.CITY,
          state: currentStore.STATE,
          ZIP: currentStore.ZIP,

          coordinates:
            String(currentStore.LATITUDE) +
            "," +
            String(currentStore.LONGITUDE),

          partnerName: resp.data[0].PARTNERNAME,
          banner: resp.data[0].BANNERFILE,
          dateAdded: resp.data[0].DATEADDED,
          logo: resp.data[0].LOGOFILE,
          partnerDescription: resp.data[0].PARTNERDESCRIPTION,
          givebackPercent: resp.data[0].GIVEBACKPERCENT,
          active: resp.data[0].ACTIVE,
        });
      }
    } catch (error) {}
    setIsLoading(false);
  };

  const [tempBanner, setTempBanner] = useState();
  const [editImage, setEditImage] = useState(false);
  const cropperRef = useRef(null);
  const onSaveCrop = async (e) => {
    e.preventDefault();
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    // console.log(cropper.getCroppedCanvas().toDataURL());
    const base64 = cropper.getCroppedCanvas().toDataURL();
    setPartnerImages({
      ...partnerImages,
      bannerFile: base64.replace(/^data:image\/[a-z]+;base64,/, ""),
    });
    setPartner({
      ...partner,
      banner: cropper.getCroppedCanvas().toDataURL(),
    });
    setFileBanner(cropper.getCroppedCanvas().toDataURL());
    setTempBanner(cropper.getCroppedCanvas().toDataURL());
    setEditImage(false);
  };

  const onClearCrop = (e) => {
    e.preventDefault();
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (cropper) {
      cropper.clear();
    }
    setFileBanner(null);
    setTempBanner(null);
    setPartner({
      ...partner,
      banner: tempPartner.banner,
    });
    setPartnerImages(null);
    setEditImage(false);
    // console.log(cropper.getCroppedCanvas().toDataURL());
  };
  const [full, setFull] = useState(null);
  const [val, setVal] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [inputVal, setInputVal] = useState("");

  const onChangeCategories = (e) => {
    const { value, checked } = e.target;
    setTempPartner({
      ...tempPartner,
      categoryID: checked
        ? [...tempPartner.categoryID, value]
        : tempPartner.categoryID.filter((category) => category !== value),
    });
  };

  useEffect(() => {
    handleChange();
  }, [val]);
  const getSuburbCity = (results) => {
    console.log(results);
    let suburb = "";
    let city = "";
    let postalCode = "";
    let state = "";
    let streetAddress = "";
    results.address_components.forEach((comp) => {
      if (comp.types.includes("sublocality")) suburb = comp.short_name;
      if (comp.types.includes("locality")) city = comp.short_name;
      if (comp.types.includes("postal_code")) postalCode = comp.short_name;
      setIsValid(true);
      if (comp.types.includes("administrative_area_level_1"))
        state = comp.long_name;
    });
    streetAddress = results.formatted_address.split(",")[0];
    return [suburb, city, postalCode, state, streetAddress];
  };
  const handleChange = async () => {
    if (val !== "") {
      const latLng = await geocodeByAddress(val);
      let lat = latLng[0].geometry.location.lat();
      let lng = latLng[0].geometry.location.lng();
      const [suburb, city, postalCode, state, streetAddress] =
        await geocodeByAddress(val).then((results) =>
          getSuburbCity(results[0])
        );
      /*     console.log('geocodeByAddressgeocodeByAddress',suburb)
            console.log('postalCodepostalCodepostalCode',postalCode)*/

      setTempPartner({
        ...partner,
        suburb: suburb,
        city: city,
        state: state,
        ZIP: postalCode,
        streetAddress1: streetAddress,
        coordinates: String(lat) + "," + String(lng),
      });
    }
  };

  useEffect(() => {
    const item = localStorage.getItem("initialPopupManageProfile");
    if (item && item === "1") {
      setShowInitialPopup(true);
      localStorage.setItem("initialPopupManageProfile", "");
    }
  }, []);

  return (
    <>
      <Card>
        <Loader isLoading={isLoading} />
        <CardTitle className="border-bottom p-3 mb-0 font-weight-bold text-uppercase">
          Manage profile
          {/*
                     <i class="fas fa-user-circle"></i>
                    <Link to="/profile-manager/edit-profile" onClick={toggle}>
                        <Button
                            className="btn"
                            color="primary"
                            size="md"
                            style={{ float: "right" }}
                        >
                            <i className="fas fa-pen-square" /> Edit Profile
                        </Button>
                    </Link> */}
          {edit ? (
            <Button
              className="btn"
              color="secondary"
              size="md"
              onClick={() => cancelEdit()}
              style={{ float: "right", marginLeft: 10 }}
            >
              <i className="fas fa-times" /> Cancel
            </Button>
          ) : null}
          <Button
            className="btn"
            color="primary"
            size="md"
            onClick={initEdit}
            style={{ float: "right" }}
            data-tour="reactour__3"
          >
            <i className="fas fa-pen-square" />{" "}
            {edit ? "Update" : "Edit Profile"}
          </Button>
          <div
            style={{ float: "right", marginRight: 16, marginTop: 6 }}
            onMouseOver={() => setShowInitialPopup(true)}
            onMouseOut={() => setShowInitialPopup(false)}
          >
            <InfoIcon width={25} height={25} color={"#808080"} />
          </div>
          {isInitialPopup && (
            <div
              style={{ float: "right", marginRight: 16, position: "relative" }}
            >
              <img
                src={require("../../assets/images/intro2.png")}
                alt=""
                style={{
                  width: 230,
                  position: "absolute",
                  right: 0,
                  top: -11,
                }}
              />
            </div>
          )}
        </CardTitle>
        <CardBody className="row font-weight-bolder text-muted">
          <div className={"row"}>
            <div
              className="col-12"
              style={{
                paddingLeft: "14%",
              }}
            >
              <div
                style={{
                  maxWidth: "900px",
                  width: "100%",
                  marginBottom: "30px",
                }}
              >
                To set up your Give-Back Business profile, complete the
                information below, select the categories you’d like to be listed
                under and provide a brief description of your business.
              </div>
            </div>
            <div className="col-6 row">
              <div
                className="mb-0 col-3 ml-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <span className="font-weight-medium">
                  <br />
                </span>{" "}
                <img
                  src={partner.logo}
                  alt={`${partner.companyName} Logo`}
                  style={{
                    width: "125px",
                    height: "125px",
                    borderRadius: "50%",
                  }}
                />
                {edit ? (
                  <section
                    className="container overlayControl"
                    style={{
                      width: "125px",
                      height: "125px",
                      borderRadius: "50%",
                    }}
                  >
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        setFileLogo(
                          acceptedFiles.map((file) =>
                            Object.assign(file, {
                              preview: URL.createObjectURL(file),
                            })
                          )
                        );
                        setPartner({
                          ...partner,
                          logo: acceptedFiles[0].preview,
                        });
                      }}
                      name="logoFile"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({
                            className: "dropzone",
                          })}
                          style={{
                            width: "125px",
                            height: "125px",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                        >
                          <input
                            id="logo"
                            {...getInputProps()}
                            onChange={(a) => {
                              handleFileReadLogo(a, false);
                              setPartner({
                                ...partner,
                                logo: URL.createObjectURL(a.target.files[0]),
                              });
                            }}
                          />
                          <div className="perfectCenter">
                            <i className="fas fa-upload" />
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </section>
                ) : null}
              </div>
              <div className="col-9 row align-content-start">
                <div className="mb-3 col-12" style={{ whiteSpace: "nowrap" }}>
                  <span className="font-weight-bold">
                    Business Name <br />
                  </span>{" "}
                  <input
                    disabled={edit ? false : true}
                    className="form-controls-light"
                    value={tempPartner.partnerName}
                    placeholder={tempPartner.partnerName}
                    onChange={(e) =>
                      setTempPartner({
                        ...tempPartner,
                        partnerName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-3 col-12">
                  <span className="font-weight-bold">Address</span>
                  {edit ? (
                    <GooglePlacesAutoComplete
                      apiKey="AIzaSyDgWDAYziwrfcUclkU-1R52RnzlWr0RT0s"
                      className="form-controls-light"
                      selectProps={{
                        styles: {
                          control: (provided) => ({
                            ...provided,
                            border: !isValid
                              ? "1px solid red"
                              : "0.5px solid grey",
                          }),
                        },
                        placeholder: val
                          ? val
                          : tempPartner
                          ? tempPartner.streetAddress1
                          : "Start typing your address",
                        onInputChange: (e) => {
                          setInputVal(e);
                        },
                        onChange: (e) => {
                          setFull(e);
                          setVal(e.value.description);
                          setTempPartner({
                            ...tempPartner,
                            streetAddress1: e.value.description,
                          });
                        },
                        onMenuClose: () => {
                          setInputVal(val);
                        },
                        onFocus: (e) => {
                          setVal(inputVal);
                          setInputVal("");
                          setFull(null);
                        },
                        inputValue: inputVal,
                        value: tempPartner ? tempPartner.streetAddress1 : "",
                        blurInputOnSelect: true,
                      }}
                    />
                  ) : (
                    <>
                      <input
                        disabled={true}
                        className="form-controls-light"
                        value={tempPartner.streetAddress1}
                        placeholder="Start typing your address"
                      />
                    </>
                  )}

                  {!isValid ? (
                    <p
                      style={{
                        position: "absolute",
                        bottom: -10,
                        margin: 0,
                        fontSize: 12,
                        color: "red",
                      }}
                    >
                      Please select an address with a zip code
                    </p>
                  ) : null}
                </div>
                <div className="mb-3 col-12">
                  <span className="font-weight-bold">
                    Email Address <br />
                  </span>{" "}
                  <input
                    disabled={edit ? false : true}
                    className="form-controls-light"
                    value={tempPartner.emailAddress}
                    placeholder={tempPartner.emailAddress}
                    onChange={(e) =>
                      setTempPartner({
                        ...tempPartner,
                        emailAddress: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-3 col-12">
                  <span className="font-weight-bold">
                    Store Number <br />
                  </span>{" "}
                  {edit ? (
                    <PhoneInput
                      className="form-controls-light"
                      id="mobileNumber"
                      name="mobileNumber"
                      defaultCountry="US"
                      style={{
                        display: "flex",
                      }}
                      value={tempPartner.contactNumber}
                      placeholder="Mobile Number"
                      onChange={(e) =>
                        setTempPartner({
                          ...tempPartner,
                          contactNumber: e,
                        })
                      }
                    />
                  ) : (
                    <>
                      <input
                        disabled={true}
                        className="form-controls-light"
                        value={tempPartner.contactNumber}
                        placeholder="Mobile Number"
                      />
                    </>
                  )}
                </div>
                <div className="mb-3 col-12">
                  <span className="font-weight-bold">
                    Select categories to be listed under <br />
                  </span>{" "}
                  <div className="row">
                    <div className="col-6">
                      <div className="form-check">
                        <input
                          disabled={edit ? false : true}
                          checked={tempPartner.categoryID?.includes("1")}
                          value="1"
                          onChange={onChangeCategories}
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault1"
                        >
                          Restaurant
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-check">
                        <input
                          disabled={edit ? false : true}
                          checked={tempPartner.categoryID?.includes("4")}
                          onChange={onChangeCategories}
                          className="form-check-input"
                          type="checkbox"
                          value="4"
                          id="flexCheckDefault2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault2"
                        >
                          Retail
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-check">
                        <input
                          disabled={edit ? false : true}
                          checked={tempPartner.categoryID?.includes("2")}
                          onChange={onChangeCategories}
                          className="form-check-input"
                          type="checkbox"
                          value="2"
                          id="flexCheckDefault3"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault3"
                        >
                          Bar
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-check">
                        <input
                          disabled={edit ? false : true}
                          checked={tempPartner.categoryID?.includes("5")}
                          onChange={onChangeCategories}
                          className="form-check-input"
                          type="checkbox"
                          value="5"
                          id="flexCheckDefault4"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault4"
                        >
                          Pets
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-check">
                        <input
                          disabled={edit ? false : true}
                          checked={tempPartner.categoryID?.includes("3")}
                          onChange={onChangeCategories}
                          className="form-check-input"
                          type="checkbox"
                          value="3"
                          id="flexCheckDefault5"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault5"
                        >
                          Coffee
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-check">
                        <input
                          disabled={edit ? false : true}
                          checked={tempPartner.categoryID?.includes("9")}
                          onChange={onChangeCategories}
                          className="form-check-input"
                          type="checkbox"
                          value="9"
                          id="flexCheckDefault6"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault6"
                        >
                          Personal Care
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3 col-12">
                  <span className="font-weight-bold">
                    Business Description:
                    <br />
                  </span>
                  <textarea
                    disabled={edit ? false : true}
                    maxLength="250"
                    type="text"
                    className="form-controls-light font-weight-light text-muted descripton"
                    style={{ textTransform: "none" }}
                    value={tempPartner.partnerDescription}
                    placeholder={tempPartner.partnerDescription}
                    onChange={(e) =>
                      setTempPartner({
                        ...tempPartner,
                        partnerDescription: e.target.value,
                      })
                    }
                  />
                  <p>{tempPartner?.partnerDescription?.length}/250</p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="mb-0 col-12 p-0">
                {!editImage && partner.banner ? (
                  <img
                    src={tempBanner ? tempBanner : partner.banner}
                    alt={`${partner.companyName} Banner`}
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                ) : (
                  ""
                )}

                {edit ? (
                  editImage ? (
                    <>
                      <Cropper
                        src={tempBanner}
                        style={{ height: 400, width: 600 }}
                        aspectRatio={6 / 3}
                        guides={false}
                        //crop={onCrop}
                        ref={cropperRef}
                      />
                      <div style={{ dispay: "flex", margin: "30px 0" }}>
                        <button
                          onClick={(e) => onClearCrop(e)}
                          className="btn btn-primary mt-3 mr-2 "
                          style={{ float: "left" }}
                        >
                          Clear
                        </button>
                        <button
                          onClick={(e) => onSaveCrop(e)}
                          className="btn btn-primary mt-3 "
                          style={{ float: "left" }}
                        >
                          Save Image
                        </button>
                      </div>
                    </>
                  ) : (
                    <section
                      className="container overlayControl"
                      style={{ minHeight: 400 }}
                    >
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          setFileBanner(
                            acceptedFiles.map((file) => {
                              Object.assign(file, {
                                preview: URL.createObjectURL(file),
                              });
                              handleFileReadBanner(file, true);
                            })
                          );
                          setTempBanner(acceptedFiles[0].preview);
                          setPartner({
                            ...partner,
                            banner: acceptedFiles[0].preview,
                          });
                          setEditImage(true);
                        }}
                        name="logoFile"
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps({
                              className: "dropzone overlayControl",
                            })}
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              id="banner"
                              {...getInputProps()}
                              onChange={(e) => {
                                handleFileReadBanner(e, false);
                                setTempBanner(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                setEditImage(true);
                                setPartner({
                                  ...partner,
                                  banner: URL.createObjectURL(
                                    e.target.files[0]
                                  ),
                                });
                              }}
                            />
                            <div className="perfectCenter">
                              <i className="fas fa-upload" />
                              <p className="">
                                Drag and Drop your image or click to upload
                              </p>
                              <p className="">
                                Using copyrighted images without permission is
                                illegal, so ensure you use original, or
                                royalty-free images for your business.
                              </p>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </section>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <Route
        path="/profile-manager/edit-profile"
        key="AddStore"
        render={(props) => (
          <ProfileEdit
            {...props}
            toggle={toggle}
            modal={modal}
            partnerInfo={partner}
          />
        )}
      />
    </>
  );
}

export default Profile;
