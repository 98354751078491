import React, { useState, useContext } from "react";
import { Card, CardTitle, CardBody, CardFooter, Button } from "reactstrap";
import { AUTH } from "../../api/config/endpoints";
import { post, setToken } from "../../api/index";
import { AuthContext } from "../../context/authProvider";
import { useHistory } from "react-router-dom";
import Loader from "../../components/loader/loader";
import loginBg from "../../assets/images/background/LoginBg.jpg";
import partnerService from "../../api/services/partnerService";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { ToastContext } from "../../context/toastProvider";
import { emit } from "../../helper/eventEmitter";

export default function Login() {
  const [username, setUsername] = useState(null);
  const history = useHistory();
  const [forgotPassword, setForgotPassword] = useState({
    active: false,
  });
  const [password, setPassword] = useState(null);
  const [error, setError] = useState({ error: false, message: "" });
  const { callLogin, callToken, callName, setUser, callId } =
    useContext(AuthContext);
  const [clientToken, setClientToken] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [mobileNumber, setMobileNumber] = useState();
  const { initToast } = useContext(ToastContext);
  const [otp, setOtp] = useState({
    otpActive: false,
    otp: "",
    ott: "",
    message: "",
  });

  const passwordReset = async () => {
    setIsLoading(true);
    try {
      const resetResp = await partnerService.resetPin({
        mobileNumber: mobileNumber,
      });
      if (resetResp.status) {
        initToast("success", resetResp.message);
        setIsLoading(false);
        setForgotPassword({ active: false });
      } else {
        initToast("error", resetResp.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  async function login() {
    setIsLoading(true);
    setError({ message: false });
    if (!otp.otpActive) {
      const tokenResp = await post(AUTH.initClient, {
        staticToken: "TRGV34JKLOIVghjknuyh34421jKIVcyuvhj",
      });
      if (tokenResp.code === 0) {
        setClientToken(tokenResp.clientToken);
        const resp = await post(AUTH.login, {
          clientToken: tokenResp.clientToken,
          emailaddress: username,
          password: password,
        });
        console.log(resp);
        if (resp.success) {
          setOtp({
            otpActive: true,
            ott: resp.ott,
            message: resp.message,
          });
          setIsLoading(false);
        } else {
          setError({ error: true, message: resp.message });
          setIsLoading(false);
        }
      }
      return;
    } else {
      const resp = await post(AUTH.loginOTP, {
        clientToken: clientToken,
        otp: otp.otp,
        ott: otp.ott,
      });
      if (resp.success) {
        setToken(resp.token);
        let temp = {};
        setUser("", {
          name: resp.detail[0].FIRSTNAME + " " + resp.detail[0].LASTNAME,
          id: resp.detail[0].STAFFID,
          token: resp.token,
          loggedIn: true,
        });
        localStorage.setItem(
          "name",
          resp.detail[0].FIRSTNAME + " " + resp.detail[0].LASTNAME
        );
        localStorage.setItem("token", resp.token);
        localStorage.setItem("id", resp.detail[0].STAFFID);
        history.push("/process-voucher");
        setTimeout(() => {
          emit("loginCount", resp.loginCount);
        }, 1000);
      }
      setError({ error: true, message: resp.message });
      setIsLoading(false);
    }
  }

  return (
    <>
      <img className="loginBg" src={loginBg} />
      <div className="mt-5">
        <Card className="w-50 m-auto">
          <form>
            <CardTitle className="border-bottom p-3 mb-0 font-weight-bold text-uppercase">
              <i class="fas fa-sign-in-alt mr-2"></i>
              Login
            </CardTitle>
            <CardBody>
              <Loader isLoading={isLoading} />
              {error.error ? (
                <p class="m-0 small pl-3">{error.message}</p>
              ) : null}
              {!otp.otpActive ? (
                !forgotPassword.active ? (
                  <React.Fragment key={"login"}>
                    <div className="col-12">
                      <label for="username">
                        {forgotPassword.active
                          ? "Mobile Number"
                          : "Email Address"}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="username"
                        placeholder=""
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    {forgotPassword.active ? null : (
                      <>
                        <div className="col-12 mt-3">
                          <label for="password">Password</label>
                          <input
                            className="form-control"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            placeholder=""
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <p
                          class=" mb-0 small pl-3 text-primary font-weight-medium"
                          style={{
                            marginTop: "1.25rem",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          Show password
                        </p>
                      </>
                    )}
                    {forgotPassword.active ? null : (
                      <p
                        onClick={() =>
                          setForgotPassword({
                            ...forgotPassword,
                            active: true,
                          })
                        }
                        class=" mb-0 small pl-3 text-primary font-weight-medium"
                        style={{
                          marginTop: "1.25rem",
                          cursor: "pointer",
                        }}
                      >
                        Forgot Password?
                      </p>
                    )}
                  </React.Fragment>
                ) : (
                  <div className="col-12">
                    <label for="mobileNumber"> Mobile Number</label>
                    <PhoneInput
                      className="form-control"
                      id="mobileNumber"
                      name="mobileNumber"
                      defaultCountry="US"
                      style={{ display: "flex" }}
                      placeholder="Mobile Number"
                      onChange={(e) => setMobileNumber(e)}
                    />
                  </div>
                )
              ) : (
                <React.Fragment key={"otp"}>
                  <p class="mb-3 small pl-3">{otp.message}</p>
                  <div className="col-12">
                    <label for="otp">Enter your OTP</label>
                    <input
                      className="form-control"
                      type="text"
                      id="otp"
                      placeholder=""
                      onChange={(e) =>
                        setOtp({
                          ...otp,
                          otp: e.target.value,
                        })
                      }
                    />
                  </div>
                </React.Fragment>
              )}
            </CardBody>
            <CardFooter>
              <Button
                color="primary"
                onClick={forgotPassword.active ? passwordReset : login}
              >
                {forgotPassword.active ? "Submit" : "Login"}
              </Button>
              {forgotPassword.active ? (
                <Button
                  color="secondary ml-2"
                  onClick={() =>
                    setForgotPassword({
                      ...forgotPassword,
                      active: false,
                    })
                  }
                >
                  Cancel
                </Button>
              ) : null}
            </CardFooter>
          </form>
        </Card>
      </div>
    </>
  );
}
