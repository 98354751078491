import React, { useState } from 'react';
import {
    Row,
    Col
} from 'reactstrap';
import { Switch, Route } from 'react-router-dom';
import StoreList from '../../components/ProfileManager/StoreList'


function StoreManager() {

    return (
        <div>
            <Row>
                <Col sm={12}>
                    <StoreList />
                </Col>
            </Row>
        </div>
    )
}

export default StoreManager