import React, { useState, useContext } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import Switch from "react-switch";
import staffService from "../../api/services/staffService";
import { ToastContext } from "../../context/toastProvider";
import Loader from "../loader/loader";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function AddUser() {
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const { initToast } = useContext(ToastContext);
  const [mobileNumber, setMobileNumber] = useState();
  const history = useHistory();

  return (
    <Card>
      <CardTitle className="border-bottom p-3 mb-0 font-weight-bold text-uppercase">
        <i class="fas fa-user-plus"></i>
        Add User
      </CardTitle>
      <CardBody>
        <Loader isLoading={isLoading} />
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            emailAddress: "",
            mobileNumber: "",
            staffUID: "",
            roleID: "1",
            storeID: "29",
          }}
          enableReinitialize
          onSubmit={async (values) => {
            let temp = { ...values, mobileNumber: mobileNumber };
            console.log(temp);
            setIsLoading(true);
            try {
              const resp = await staffService.addStaffMember({
                ...temp,
                active: active ? "1" : "0",
              });
              if (resp.success) {
                setIsLoading(false);
                history.goBack();
                initToast("success", resp.message);
              } else {
                setIsLoading(false);
                initToast("error", resp.message);
              }
            } catch (error) {
              setIsLoading(false);
            }
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form className="row g-1" onSubmit={handleSubmit}>
              <div className="col-6">
                <label for="firstName">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  name="firstName"
                />
              </div>
              <div className="col-6">
                <label for="surname">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  name="lastName"
                />
              </div>
              <div className="col-6 mt-2">
                <label for="email">Email Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="emailAddress"
                  value={values.emailAddress}
                  onChange={handleChange}
                  name="emailAddress"
                />
              </div>
              <div className="col-6 mt-2">
                <label for="number">Mobile Number</label>
                <PhoneInput
                  className="form-control"
                  id="mobileNumber"
                  name="mobileNumber"
                  defaultCountry="US"
                  style={{ display: "flex" }}
                  value={mobileNumber}
                  placeholder="Mobile Number"
                  onChange={(e) => setMobileNumber(e)}
                />
              </div>
              <div className="col-6 mt-2">
                <label for="roleID">Role</label>
                <select
                  className="form-control"
                  aria-label="Select A Charity"
                  id="roleID"
                  name="roleID"
                  onChange={handleChange}
                >
                  <option value="1">Admin</option>
                  <option value="2">Staff</option>
                </select>
              </div>
              <div className="col-6 mt-3">
                <label for="active">Active Staff Member</label>
                <div>
                  <Switch
                    onChange={() => setActive(!active)}
                    checked={active}
                    onColor={"#7460ee"}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
              <div className="col-12" style={{ marginTop: "1.6em" }}>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
                <Link to="/user-manager">
                  <button
                    type="submit"
                    className="ml-3 btn btn-outline-secondary"
                  >
                    Back
                  </button>
                </Link>
              </div>
            </form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
}

export default AddUser;
