import React from "react";
import { Col } from "reactstrap";
import { Feeds, TotalRevenue } from "../../components/dashboard";
import { VoucherContext } from "../../context/ValidateVoucherContext";

export default function RevenueAndFeed() {
  const { data, series } = React.useContext(VoucherContext);

  return (
    <>
      <Col sm={6} lg={8}>
        <TotalRevenue series={series} />
      </Col>
      <Col sm={6} lg={4}>
        <Feeds data={data} />
      </Col>
    </>
  );
}
